import React, { PureComponent } from 'react';
import { useState, useCallback, useEffect, useRef} from 'react';


import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';


import styled from 'styled-components'
import axios from 'axios';

import SignatureCanvas from 'react-signature-canvas'
import {Buffer} from 'buffer'
import Modal from 'react-modal'
import {v4} from 'uuid'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"


import './Paging.css'
import Pagination from 'react-js-pagination';
import Write from './Write';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



const Block = styled.div`
  overflow:auto;

  th, td {
    border: 1px solid lightgrey;
    padding: 3px;
    text-align: center ;
  }

`


const customStyles ={
  content: {
      top:'50%',
      left:'50%',
      right:'auto',
      bottom:'auto',
      marginRight:'-50%',
      transform: 'translate(-50%, -50%)',
      
  },
  border:{
    border: 'solid'
  }
  

 
  
}



Modal.setAppElement('#root')
const Generator_Write = ({_id, location, refCategory}) => {
  const [datas, setDatas] =useState(null)
  const [refDatas, setRefDatas] =useState(null)

  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies(['auth']);
  const [modalIsOpen, setIsOpen] = useState(false);
  const signCanvas = useRef() 
  const componenetRef = useRef();
  

    //console.log(match.params.location)
    //const data = match.params
   
    //const location = data.location
    
    const [category, setCategory] = useState('elec')
    const [writeOn,setWriteOn] = useState(false)
    const [idInform,setIdInform] = useState(null)
    const [login, setLogin] = useState(true)

    const [ inChargePerson, setInChargePerson ] = useState('')
    const [startDate, setStartDate] = useState(new Date());
    const [selectDate, setSelectDate] = useState(null);
    const [endDate, setEndDate] = useState(new Date());
    const [signPath1, setSignPath1] = useState(null)
    const [signPath2, setSignPath2] = useState(null)
    const [signPath3, setSignPath3] = useState(null)
    const [detailView, setDetailView] = useState(false)


    const [filterOn, setFilterOn] = useState(false)
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pagelimit, setPagelimit] = useState(10);
    const handlePageChange = page => {
        
      setFilterOn(false)
      setPage(page)
      setDatas(refDatas.slice((page-1)*pagelimit,(page*pagelimit)))
               
  }

  const [form, setForm] = useState ({
    
  })

  const onChangeComment= useCallback((name,comment) => {
    
    //console.log(name)
    const nextForm = {
      ...form,
      [name]: comment
    };

    
    setForm(nextForm);
   
    console.log(form)
    
},[form]);


  const today =(value) => {
    const year = value.getFullYear();
    const month = ('0' +(value.getMonth()+1)).slice(-2);
    const day = ('0' + value.getDate()).slice(-2);
    const dateString = year + '-' + month +'-' + day;

    return dateString
  }

  const dateFilter = useCallback(() => {
    const filterData = refDatas.filter(refData => {
      //console.log(refData.createdAt)
      //if(today( new Date(new Date(refData.createdAt) - 32400000)) >= today(startDate) && today(new Date(new Date(refData.createdAt) - 32400000)) <= today(endDate)){
      if(today( new Date(refData.createdAt)) >= today(startDate) && today(new Date(refData.createdAt)) <= today(endDate)){
        return true
      }
    })
    console.log(startDate)
    
    console.log(filterData)
    setDatas(filterData)
    setFilterOn(true);
  })

  

    const [ tableIndex, setTableIndex ] = useState(null)

 

    const onChange = useCallback(e => {
    
    
      const nextForm = {
        ...form,
        [e.target.name]: e.target.value
      };

      
      setForm(nextForm);
     
      
  },[form]);

  useEffect(() => {

    if (_id != null){
      //쓰기가 아닐경우 개별 아이디 조회 
      fetchGetData(_id);
      

    }

  },[])

  



  const fetchGetData = async(_id) => {

    try{
      
      const response = await axios.get(
        `${process.env.REACT_APP_SOCKET}/plantdata/detail?id=${_id}`,{
            headers:{
                token : cookie.auth
            }
          }
      );

      setDatas(response.data[0])
      setRefDatas(response.data[0])
      setTotalCount(response.data[0].length)
      setSelectDate(new Date(response.data[0].createdAt))
      setForm(response.data[0].dataList[0])
      console.log(response.data[0])

       


    }catch (e) {
        //console.log(e);

       
    }
  }


  const fetchLoginCheck = async() => {

    try{
      
        const response = await axios.get(
            `${process.env.REACT_APP_HOST_RPA}/rpa/account/test`,{
                headers:{
                    token : cookie.auth
                }
            }
        );
        
            //console.log(response.data)
        
        if(response.data.code == 419 || response.data.code == 401)
        {
            //alert("토큰만료")
            history.push("/login")
        }

        //


    }catch (e) {
        //console.log(e);

        history.push("/login")
       
    }
  }



  

  const doneWrite = async() => {
    //alert("작성되었습니다.")
    
    try{
      
      const response = await axios.post(
        `${process.env.REACT_APP_SOCKET}/plantdata?type=${refCategory}&date=${startDate}&location=${location}`,{
            dataList : form,
            headers:{
                'token': cookie.auth
            }
        });
        alert("등록되었습니다.")
        
        window.location.reload();
        
        //form.clear()
        
    
      //console.log(response.data.length)

     


    }catch (e) {
        console.log(e);
        alert(e)
      
    }

   
    
}

const doneMody = async() => {
  //alert("작성되었습니다.")
  
  
  
  if(window.confirm("수정할까요?")){
      
   
           //캔버스 저장 및 평가 결과 
          //save()           

        try{
    
            const response = await axios.put(
              `${process.env.REACT_APP_SOCKET}/plantdata/detail?id=${_id}&type=${refCategory}&date=${selectDate - 32400000 }&location=${location}`,{
                  dataList : form,
                  headers:{
                      'token': cookie.auth
                  }
              });
              alert("수정되었습니다..")
              
              window.location.reload();
              
              //form.clear()
              
          
            //console.log(response.data.length)
    
           
    
    
        }catch (e) {
            console.log(e);
            alert(e)
           
        }

          //fetchData()
          

          //setIsOpen(false)
          //fetchGetData()
          
          
          
  
  }else{
      //취소
      return false
  }
 
  
}


//서명 클리어
const clear = () => {
    //캔버스 클리어
    signCanvas.current.clear();
}
//서명 저장
const save = () => {
    
    console.log("캔버스 저장")
    
    const dataURL = signCanvas.current.toDataURL('image/png')
    const decodeURL = dataURL.replace(/^data:image\/\w+;base64,/,'');
    const buf = Buffer.from(decodeURL, 'base64')
    const blob = new Blob([buf], {type:'image/png'})
    const file = new File([blob], `${v4()}.png`,{type:'image/png'})
    console.log(file)
    
    const formData = new FormData();
    formData.append('sign', file)

    /*
    axios({
        baseURL : process.env.REACT_APP_HOST,
        url: '/pm/uploadsignimg',
        method: 'POST',
        data: formData,
        headers:{
            'Content-Type' : 'multipart/form-data',              
        },
    })
        .then(response => {
            //console.log(response.data)
            //console.log(response.data.originalname)
            
            //setReturnOriginalFilename(response.data.originalname)
            console.log(response.data.filename)
            if(inChargePerson == '담당자'){
              setSignPath1(response.data.filename)
            }else if(inChargePerson == '책임'){
              setSignPath2(response.data.filename)
            }else if(inChargePerson == '리더'){
              setSignPath3(response.data.filename)
            }
            //enrollResult(response.data.filename)

                           
            

        })
        .catch(error => {
            console.error(error)
        })

    */
   
    
}



  const openModal = async(refPerson) => {
    setIsOpen(!modalIsOpen)
    
    console.log(refPerson)
    setInChargePerson(refPerson)

    
    
}



const afterOpenModal = () => {
   
}

const closeModal = () => {
    setIsOpen(false)
    
 
    console.log("close")
}

 

    return(

      
            <Block>
               
               {_id == null ? <>
               
                  <div>
                    
                    <table>
                      
                      <tr>
                        
                        <td colspan='6'> 
                        <div  style={{float:'left'}}>
                          <DatePicker style={{float:'left'}} selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="yy-MM-dd"/>  
                          </div>
                          <div  style={{float:'left'}}>
                          &emsp;&emsp;점검주기 : Monthly  
                          </div>
                          <div style={{float:'right'}}>
                            점검자 : <input type='text' name='sub11' onChange={onChange}></input> &emsp;&emsp; / 입회자 : <input type='text' name='sub12'onChange={onChange}></input>
                          </div>
                          
                        </td>
                        
                      </tr>
                      <tr >
                        <td rowSpan='2'>
                          Plant
                        </td>
                        <td rowSpan='2'>
                          LMCC
                        </td>
                        <td>
                          ITEM NO
                        </td>
                        <td>
                          CAPACITY
                        </td>
                        <td colspan='2'>
                          점검 운전시간
                        </td>
                        
                      </tr>
                      <tr>
                        <td>
                          MMA-51-GEN-001
                        </td>
                        <td>
                          500 KVA<br/>
                          ( 400kw )
                        </td>
                        <td>
                          오전 / 오후
                        </td>
                        <td>
                          <input type='text' name='sub13' onChange={onChange} /> (10분 운전)
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <table>
                      <tr>
                        <td rowSpan='2'>
                          상태표기
                        </td>
                        <td>
                          정상
                        </td>
                        <td>
                          불량
                        </td>
                        <td>
                          요주의
                        </td>
                        <td>
                          이음
                        </td>
                        <td>
                          이취
                        </td>
                        <td>
                          발열
                        </td>
                        <td>
                          진동
                        </td>
                        <td>
                          누유
                        </td>
                        <td>
                          부족
                        </td>
                        <td>
                          기타
                        </td>
                        <td>
                          실내온도
                        </td>
                      </tr>
                      <tr>
                        <td>
                          ○
                        </td>
                        <td>
                          X
                        </td>
                        <td>
                          △
                        </td>
                        <td>
                          <input type='text' name='sub21' onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub22' onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub23' onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub24' onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub25' onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub26' onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub27' onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub28' onChange={onChange}/>
                        </td>

                      </tr>
                    </table>
                  </div>

                  <div>
                    <table>
                      <tr>
                        <th>
                          구분
                        </th>
                        <th colspan='7'>
                          점검항목
                        </th>
                        <th>
                          점검결과
                        </th>
                      </tr>
                      <tr>
                        <td rowSpan='10'>
                          PANEL
                        </td>
                        <td>
                          전압
                        </td>
                        <td>
                          <input type='text' name='sub31' onChange={onChange}/>
                        </td>
                        <td>
                          주파수
                        </td>
                        <td>
                          <input type='text'name='sub32' onChange={onChange} />
                        </td>
                        <td>
                          회전수
                        </td>
                        <td colspan='2'>
                          <input type='text' name='sub33' onChange={onChange}/>
                        </td>
                        <td >
                          <input type='text' name='sub34' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr >
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        표시등 상태 (운전 전/중/후 상태)
                        </td>
                        <td >
                          <input type='text' name='sub35' onChange={onChange}/>
                        </td>
                        
                      </tr>
                      <tr >
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        보호계전기 상태 (DPR)
                        </td>
                        <td >
                          <input type='text' name='sub36' onChange={onChange}/>
                        </td>
                        
                      </tr>
                      <tr >
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        ENGINE CONTROL SWITCh 위치(TEST 완료 후)
                        </td>
                        <td >
                          <input type='text' name='sub37' onChange={onChange}/>
                        </td>
                        
                      </tr>
                      <tr >
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        ACB CONTROL SWITCH 위치 (TEST 완료 후)
                        </td>
                        <td >
                          <input type='text' name='sub38' onChange={onChange}/>
                        </td>
                        
                      </tr>
                      <tr >
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        READY TO AUTO START LAMP 상태
                        </td>
                        <td >
                          <input type='text' name='sub39' onChange={onChange}/>
                        </td>
                        
                      </tr>
                      <tr >
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        KEY (CAM) Switch 상태
                        </td>
                        <td >
                          <input type='text' name='sub40' onChange={onChange}/>
                        </td>
                        
                      </tr>
                      <tr >
                        
                        <td colspan='3'> 
                        BATT'충전전압
                        </td>
                        <td colspan='2'>
                          <input type='text' name='sub41' onChange={onChange}/>
                        </td>
                        <td>
                        BATT'충전전류
                        </td>
                        <td >
                          <input type='text' name='sub42' onChange={onChange}/>
                        </td>
                        <td >
                          <input type='text' name='sub43' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr >
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        RUNNING시 HRG PANEL 동작 상태
                        </td>
                        <td >
                          <input type='text' name='sub44' onChange={onChange}/>
                        </td>
                        
                      </tr>
                      <tr >
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        Emergency SW & 86(Lock-out Realy)동작 상태 
                        </td>
                        <td >
                          <input type='text' name='sub45' onChange={onChange}/>
                        </td>
                        
                      </tr>
                      <tr>
                        <td rowSpan='3'>
                          GENERATOR
                        </td>
                        <td colspan='7' style={{textAlign:'left'}} >
                        SPACE HEATER 동작 상태  / CURRENT (A)
                        </td>
                        <td >
                          <input type='text' name='sub46' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        절연저항 ( 500V / 1000MΩ)
                        </td>
                        <td >
                          <input type='text' name='sub47' onChange={onChange}/>MΩ
                        </td>
                      </tr>
                      <tr>
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        운전중 상태 
                        </td>
                        <td >
                          <input type='text' name='sub48' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan='10'>
                          ENGINE
                        </td>
                        <td rowSpan='3' colspan='6' style={{textAlign:'left'}} >
                        COOLING WATER
                        </td>
                        <td>
                          상태
                        </td>
                        <td >
                          <input type='text' name='sub49' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        
                        
                        <td>
                          LEVEL
                        </td>
                        <td >
                          <input type='text'/>
                        </td>
                      </tr>
                      <tr>
                        
                        
                        <td>
                          TEMPERATURE (기준:84℃)
                        </td>
                        <td >
                          <input type='text' name='sub50' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                      
                        <td rowSpan='4' colspan='6' style={{textAlign:'left'}} >
                        ENGINE OIL
                        </td>
                        <td>
                          상태
                        </td>
                        <td >
                          <input type='text' name='sub51' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        
                        
                        <td>
                          PRESSURE
                        </td>
                        <td >
                          <input type='text' name='sub52' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        
                        
                        <td>
                          LEVEL
                        </td>
                        <td >
                          <input type='text' name='sub53' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        
                        
                        <td>
                          TEMPERATURE
                        </td>
                        <td >
                          <input type='text' name='sub54' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        
                        
                      <td colspan='7' style={{textAlign:'left'}} >
                          ENGINE CONTROL SWITCH 위치
                        </td>
                        <td >
                          <input type='text' name='sub55' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        
                        
                      <td colspan='7' style={{textAlign:'left'}} >
                          JACKET WATER HEATER 동작상태 / Current(A)
                        </td>
                        <td >
                          <input type='text' name='sub56' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        
                        
                      <td colspan='7' style={{textAlign:'left'}} >
                          표시등 상태(운전 전 / 중 / 후 상태 )
                        </td>
                        <td >
                          <input type='text' name='sub57' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={3}>
                          FUEL OIL
                        </td>
                        <td colspan='7' style={{textAlign:'left'}} >
                          LEVEL
                        </td>
                        <td >
                          <input type='text' name='sub58' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        <td colspan='7' style={{textAlign:'left'}} >
                          METER 상태
                        </td>
                        <td >
                          <input type='text' name='sub59' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        <td colspan='7' style={{textAlign:'left'}} >
                          TANK 상태 (외관, 접지, 페인트 등)
                        </td>
                        <td >
                          <input type='text' name='sub60' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2}>
                          기 &emsp;&emsp;타
                        </td>
                        <td colspan='7' style={{textAlign:'left'}} >
                          Air Natural Damper 동작 상태 (Inlet / Outlet)
                        </td>
                        <td >
                          <input type='text' name='sub61' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        <td colspan='7' style={{textAlign:'left'}} >
                          Fire Damper Test
                        </td>
                        <td >
                          <input type='text' name='sub62' onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={5}>
                          BATTERY
                        </td>
                        <td colSpan='2'>
                          구분 
                        </td>
                        <td>
                          CELL 1
                        </td>
                        <td >
                          CELL 2
                        </td>
                        <td>
                          CELL 3
                        </td>
                        <td > 
                          CELL 4
                        </td>
                        <td>
                          CELL 5
                        </td>
                        <td>
                          CELL 6
                        </td>
                        
                        
                      </tr>
                      <tr>
                        
                        <td colSpan='2'>
                          전압(DC V) 
                        </td>
                        <td>
                          <input type='text' name='sub71' onChange={onChange}/>
                        </td>
                        <td >
                          <input type='text' name='sub72' onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub73' onChange={onChange} />
                        </td>
                        <td > 
                          <input type='text' name='sub74' onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub75' onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub76' onChange={onChange}/>
                        </td>
                        
                        
                      </tr>
                      <tr>
                        
                        <td colSpan='2'>
                          내부저항(mΩ) 
                        </td>
                        <td>
                          <input type='text' name='sub81' onChange={onChange} />
                        </td>
                        <td >
                          <input type='text' name='sub82' onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub83' onChange={onChange}/>
                        </td>
                        <td > 
                          <input type='text' name='sub84' onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub85' onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub86' onChange={onChange}/>
                        </td>
                        
                        
                      </tr>
                      <tr>
                        
                        <td colSpan='2'>
                          외관상태
                        </td>
                        <td>
                          <input type='text' name='sub91' onChange={onChange}/>
                        </td>
                        <td >
                          <input type='text' name='sub92' onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub93' onChange={onChange}/>
                        </td>
                        <td > 
                          <input type='text' name='sub94' onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub95' onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub96' onChange={onChange}/>
                        </td>
                        
                        
                      </tr>
                      <tr>
                        <td colspan='8' style={{textAlign:'left'}} >
                            Battery 내부 저항 기준 (RP 200 ~ 12 Type) <br/>
                            ✲ 초기 측정값 : Vender(2.0mΩ)이나 실측한 값으로 적용함 (: 2.40mΩ)<br/>
                            ✲ 정상값 : 초기평균값 X 1.3이하 <br/>
                            ✲ 주의값 : 초기평균값 X 1.3 ~ 1.5 <br/>
                            ✲ 경고값 : 초기평균값 X 1.5 ~ 2.0 <br/>
                            ✲ 재확인및 교체 필요값 : 초기평균값 X 2.0 이상 <br/>

                        </td>
                      </tr>
                      <tr>
                        <td>특기사항</td>
                        <td colspan='8' style={{textAlign:'left'}} >
                          <CKEditor
                          
                          editor={ ClassicEditor }
                          onChange={(event,editor)=>{
                              onChangeComment("comment",editor.getData())
                          }}
                          />
                        </td>
                      </tr>
                      
                    </table>
                  </div>

                  
                  <div >
                    
                    <button onClick={() => doneWrite()}>등록하기</button>
                  </div>

                </>:<>
                  {datas != null &&<>

               
                    <div>
                    
                    <table>
                      
                      <tr>
                        
                        <td colspan='6'> 
                        <div  style={{float:'left'}}>
                          <DatePicker style={{float:'left'}} selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="yy-MM-dd"/>  
                          </div>
                          <div  style={{float:'left'}}>
                          &emsp;&emsp;점검주기 : Monthly  
                          </div>
                          <div style={{float:'right'}}>
                            점검자 : <input type='text' name='sub11' defaultValue={datas.dataList[0].sub11} onChange={onChange}></input> &emsp;&emsp; / 입회자 : <input type='text' name='sub12' defaultValue={datas.dataList[0].sub12} onChange={onChange}></input>
                          </div>
                          
                        </td>
                        
                      </tr>
                      <tr >
                        <td rowSpan='2'>
                          Plant
                        </td>
                        <td rowSpan='2'>
                          LMCC
                        </td>
                        <td>
                          ITEM NO
                        </td>
                        <td>
                          CAPACITY
                        </td>
                        <td colspan='2'>
                          점검 운전시간
                        </td>
                        
                      </tr>
                      <tr>
                        <td>
                          MMA-51-GEN-001
                        </td>
                        <td>
                          500 KVA<br/>
                          ( 400kw )
                        </td>
                        <td>
                          오전 / 오후
                        </td>
                        <td>
                          <input type='text' name='sub13' defaultValue={datas.dataList[0].sub13} onChange={onChange} /> (10분 운전)
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <table>
                      <tr>
                        <td rowSpan='2'>
                          상태표기
                        </td>
                        <td>
                          정상
                        </td>
                        <td>
                          불량
                        </td>
                        <td>
                          요주의
                        </td>
                        <td>
                          이음
                        </td>
                        <td>
                          이취
                        </td>
                        <td>
                          발열
                        </td>
                        <td>
                          진동
                        </td>
                        <td>
                          누유
                        </td>
                        <td>
                          부족
                        </td>
                        <td>
                          기타
                        </td>
                        <td>
                          실내온도
                        </td>
                      </tr>
                      <tr>
                        <td>
                          ○
                        </td>
                        <td>
                          X
                        </td>
                        <td>
                          △
                        </td>
                        <td>
                          <input type='text' name='sub21' defaultValue={datas.dataList[0].sub21} onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub22' defaultValue={datas.dataList[0].sub22} onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub23' defaultValue={datas.dataList[0].sub23} onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub24' defaultValue={datas.dataList[0].sub24} onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub25' defaultValue={datas.dataList[0].sub25} onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub26' defaultValue={datas.dataList[0].sub26} onChange={onChange}/>
                        </td>sub21
                        <td>
                          <input type='text' name='sub27' defaultValue={datas.dataList[0].sub27} onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub28' defaultValue={datas.dataList[0].sub28} onChange={onChange}/>
                        </td>

                      </tr>
                    </table>
                  </div>

                  <div>
                    <table>
                      <tr>
                        <th>
                          구분
                        </th>
                        <th colspan='7'>
                          점검항목
                        </th>
                        <th>
                          점검결과
                        </th>
                      </tr>
                      <tr>
                        <td rowSpan='10'>
                          PANEL
                        </td>
                        <td>
                          전압
                        </td>
                        <td>
                          <input type='text' name='sub31' defaultValue={datas.dataList[0].sub31} onChange={onChange}/>
                        </td>
                        <td>
                          주파수
                        </td>
                        <td>
                          <input type='text'name='sub32' defaultValue={datas.dataList[0].sub32} onChange={onChange} />
                        </td>
                        <td>
                          회전수
                        </td>
                        <td colspan='2'>
                          <input type='text' name='sub33' defaultValue={datas.dataList[0].sub33} onChange={onChange}/>
                        </td>
                        <td >
                          <input type='text' name='sub34' defaultValue={datas.dataList[0].sub34} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr >
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        표시등 상태 (운전 전/중/후 상태)
                        </td>
                        <td >
                          <input type='text' name='sub35' defaultValue={datas.dataList[0].sub35} onChange={onChange}/>
                        </td>
                        
                      </tr>
                      <tr >
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        보호계전기 상태 (DPR)
                        </td>
                        <td >
                          <input type='text' name='sub36' defaultValue={datas.dataList[0].sub36} onChange={onChange}/>
                        </td>
                        
                      </tr>
                      <tr >
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        ENGINE CONTROL SWITCh 위치(TEST 완료 후)
                        </td>
                        <td >
                          <input type='text' name='sub37' defaultValue={datas.dataList[0].sub37} onChange={onChange}/>
                        </td>
                        
                      </tr>
                      <tr >
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        ACB CONTROL SWITCH 위치 (TEST 완료 후)
                        </td>
                        <td >
                          <input type='text' name='sub38' defaultValue={datas.dataList[0].sub38} onChange={onChange}/>
                        </td>
                        
                      </tr>
                      <tr >
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        READY TO AUTO START LAMP 상태
                        </td>
                        <td >
                          <input type='text' name='sub39' defaultValue={datas.dataList[0].sub39} onChange={onChange}/>
                        </td>
                        
                      </tr>
                      <tr >
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        KEY (CAM) Switch 상태
                        </td>
                        <td >
                          <input type='text' name='sub40' defaultValue={datas.dataList[0].sub40} onChange={onChange}/>
                        </td>
                        
                      </tr>
                      <tr >
                        
                        <td colspan='3'> 
                        BATT'충전전압
                        </td>
                        <td colspan='2'>
                          <input type='text' name='sub41' defaultValue={datas.dataList[0].sub41} onChange={onChange}/>
                        </td>
                        <td>
                        BATT'충전전류
                        </td>
                        <td >
                          <input type='text' name='sub42' defaultValue={datas.dataList[0].sub42} onChange={onChange}/>
                        </td>
                        <td >
                          <input type='text' name='sub43' defaultValue={datas.dataList[0].sub43} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr >
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        RUNNING시 HRG PANEL 동작 상태
                        </td>
                        <td >
                          <input type='text' name='sub44' defaultValue={datas.dataList[0].sub44} onChange={onChange}/>
                        </td>
                        
                      </tr>
                      <tr >
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        Emergency SW & 86(Lock-out Realy)동작 상태 
                        </td>
                        <td >
                          <input type='text' name='sub45' defaultValue={datas.dataList[0].sub45} onChange={onChange}/>
                        </td>
                        
                      </tr>
                      <tr>
                        <td rowSpan='3'>
                          GENERATOR
                        </td>
                        <td colspan='7' style={{textAlign:'left'}} >
                        SPACE HEATER 동작 상태  / CURRENT (A)
                        </td>
                        <td >
                          <input type='text' name='sub46' defaultValue={datas.dataList[0].sub46} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        절연저항 ( 500V / 1000MΩ)
                        </td>
                        <td >
                          <input type='text' name='sub47' defaultValue={datas.dataList[0].sub47} onChange={onChange}/>MΩ
                        </td>
                      </tr>
                      <tr>
                        
                        <td colspan='7' style={{textAlign:'left'}} >
                        운전중 상태 
                        </td>
                        <td >
                          <input type='text' name='sub48' defaultValue={datas.dataList[0].sub48} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan='10'>
                          ENGINE
                        </td>
                        <td rowSpan='3' colspan='6' style={{textAlign:'left'}} >
                        COOLING WATER
                        </td>
                        <td>
                          상태
                        </td>
                        <td >
                          <input type='text' name='sub49' defaultValue={datas.dataList[0].sub49} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        
                        
                        <td>
                          LEVEL
                        </td>
                        <td >
                          <input type='text'/>
                        </td>
                      </tr>
                      <tr>
                        
                        
                        <td>
                          TEMPERATURE (기준:84℃)
                        </td>
                        <td >
                          <input type='text' name='sub50' defaultValue={datas.dataList[0].sub50} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                      
                        <td rowSpan='4' colspan='6' style={{textAlign:'left'}} >
                        ENGINE OIL
                        </td>
                        <td>
                          상태
                        </td>
                        <td >
                          <input type='text' name='sub51' defaultValue={datas.dataList[0].sub51} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        
                        
                        <td>
                          PRESSURE
                        </td>
                        <td >
                          <input type='text' name='sub52' defaultValue={datas.dataList[0].sub52}onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        
                        
                        <td>
                          LEVEL
                        </td>
                        <td >
                          <input type='text' name='sub53' defaultValue={datas.dataList[0].sub53} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        
                        
                        <td>
                          TEMPERATURE
                        </td>
                        <td >
                          <input type='text' name='sub54' defaultValue={datas.dataList[0].sub54} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        
                        
                      <td colspan='7' style={{textAlign:'left'}} >
                          ENGINE CONTROL SWITCH 위치
                        </td>
                        <td >
                          <input type='text' name='sub55' defaultValue={datas.dataList[0].sub55} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        
                        
                      <td colspan='7' style={{textAlign:'left'}} >
                          JACKET WATER HEATER 동작상태 / Current(A)
                        </td>
                        <td >
                          <input type='text' name='sub56' defaultValue={datas.dataList[0].sub56} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        
                        
                      <td colspan='7' style={{textAlign:'left'}} >
                          표시등 상태(운전 전 / 중 / 후 상태 )
                        </td>
                        <td >
                          <input type='text' name='sub57' defaultValue={datas.dataList[0].sub57} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={3}>
                          FUEL OIL
                        </td>
                        <td colspan='7' style={{textAlign:'left'}} >
                          LEVEL
                        </td>
                        <td >
                          <input type='text' name='sub58' defaultValue={datas.dataList[0].sub58} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        <td colspan='7' style={{textAlign:'left'}} >
                          METER 상태
                        </td>
                        <td >
                          <input type='text' name='sub59' defaultValue={datas.dataList[0].sub59} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        <td colspan='7' style={{textAlign:'left'}} >
                          TANK 상태 (외관, 접지, 페인트 등)
                        </td>
                        <td >
                          <input type='text' name='sub60' defaultValue={datas.dataList[0].sub60} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2}>
                          기 &emsp;&emsp;타
                        </td>
                        <td colspan='7' style={{textAlign:'left'}} >
                          Air Natural Damper 동작 상태 (Inlet / Outlet)
                        </td>
                        <td >
                          <input type='text' name='sub61' defaultValue={datas.dataList[0].sub61} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        <td colspan='7' style={{textAlign:'left'}} >
                          Fire Damper Test
                        </td>
                        <td >
                          <input type='text' name='sub62' defaultValue={datas.dataList[0].sub62} onChange={onChange}/>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={5}>
                          BATTERY
                        </td>
                        <td colSpan='2'>
                          구분 
                        </td>
                        <td>
                          CELL 1
                        </td>
                        <td >
                          CELL 2
                        </td>
                        <td>
                          CELL 3
                        </td>
                        <td > 
                          CELL 4
                        </td>
                        <td>
                          CELL 5
                        </td>
                        <td>
                          CELL 6
                        </td>
                        
                        
                      </tr>
                      <tr>
                        
                        <td colSpan='2'>
                          전압(DC V) 
                        </td>
                        <td>
                          <input type='text' name='sub71' defaultValue={datas.dataList[0].sub71} onChange={onChange}/>
                        </td>
                        <td >
                          <input type='text' name='sub72' defaultValue={datas.dataList[0].sub72} onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub73' defaultValue={datas.dataList[0].sub73} onChange={onChange} />
                        </td>
                        <td > 
                          <input type='text' name='sub74' defaultValue={datas.dataList[0].sub74} onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub75' defaultValue={datas.dataList[0].sub75} onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub76' defaultValue={datas.dataList[0].sub76} onChange={onChange}/>
                        </td>
                        
                        
                      </tr>
                      <tr>
                        
                        <td colSpan='2'>
                          내부저항(mΩ) 
                        </td>
                        <td>
                          <input type='text' name='sub81' defaultValue={datas.dataList[0].sub81} onChange={onChange} />
                        </td>
                        <td >
                          <input type='text' name='sub82' defaultValue={datas.dataList[0].sub82} onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub83' defaultValue={datas.dataList[0].sub83} onChange={onChange}/>
                        </td>
                        <td > 
                          <input type='text' name='sub84' defaultValue={datas.dataList[0].sub84} onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub85' defaultValue={datas.dataList[0].sub85} onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub86' defaultValue={datas.dataList[0].sub86} onChange={onChange}/>
                        </td>
                        
                        
                      </tr>
                      <tr>
                        
                        <td colSpan='2'>
                          외관상태
                        </td>
                        <td>
                          <input type='text' name='sub91' defaultValue={datas.dataList[0].sub91} onChange={onChange}/>
                        </td>
                        <td >
                          <input type='text' name='sub92' defaultValue={datas.dataList[0].sub92} onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub93' defaultValue={datas.dataList[0].sub93} onChange={onChange}/>
                        </td>
                        <td > 
                          <input type='text' name='sub94' defaultValue={datas.dataList[0].sub94} onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub95' defaultValue={datas.dataList[0].sub95} onChange={onChange}/>
                        </td>
                        <td>
                          <input type='text' name='sub96' defaultValue={datas.dataList[0].sub96} onChange={onChange}/>
                        </td>
                        
                        
                      </tr>
                      <tr>
                        <td colspan='8' style={{textAlign:'left'}} >
                            Battery 내부 저항 기준 (RP 200 ~ 12 Type) <br/>
                            ✲ 초기 측정값 : Vender(2.0mΩ)이나 실측한 값으로 적용함 (: 2.40mΩ)<br/>
                            ✲ 정상값 : 초기평균값 X 1.3이하 <br/>
                            ✲ 주의값 : 초기평균값 X 1.3 ~ 1.5 <br/>
                            ✲ 경고값 : 초기평균값 X 1.5 ~ 2.0 <br/>
                            ✲ 재확인및 교체 필요값 : 초기평균값 X 2.0 이상 <br/>

                        </td>
                      </tr>
                      <tr>
                        <td>특기사항</td>
                        <td colspan='8' style={{textAlign:'left'}} >
                          <CKEditor
                          
                          editor={ ClassicEditor }
                          onChange={(event,editor)=>{
                              onChangeComment("comment",editor.getData())
                          }}
                          />
                        </td>
                      </tr>
                      
                    </table>
                  </div>

                  
                  <div >
                    
                    <button onClick={() => doneMody()}>수정하기</button>
                  </div>

                


                  
                  
                  </>}
                                  
                </>}
                    
                    
                    
             
                             
              
            </Block>
    
        );
    
   
};

export default Generator_Write