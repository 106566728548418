
import React from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components'
import axios from 'axios';



const Block = styled.div`
    display:flex;
    flex-direction : column;
    font-size: 10px ;
    
    table {
        border-collapse: collapse;
        width : 98%;
    }
  
   
    table, td, th {
        border: 1px solid #ddd;
        text-align: left;
        
    }
    
    tr:nth-child(even){background-color: #f2f2f2}
      
    tr:hover {background-color: #F8F8FF;}

    th  {
        background-color: #6495ED;
        color: white;
        
        
    }

    th, td{
        min-width: 100px ;
    }

    .content {
        
    }

    .btnAuth {
        border-radius : 5px;
        width : 80px;
        
    }
   
    .btnSubmit {
        border-radius : 5px;
        width : 100%
        
    }
    
    .center{
        text-align: center ;
    }

   

`



const index = ({data}) => {
    const location = "대산"
    const refLocation ="daesan"
    const section = data.section
    //console.log(location)


    const [receivedAuthNumber, setReceivedAuthNumber] = useState(null);
    const [ sendOk, setSendOk] = useState(false)
    const [form, setForm] = useState ({})
    

      const onChange = useCallback(e => {
    
    
        const nextForm = {
          ...form,
          [e.target.name]: e.target.value
        };

        
        setForm(nextForm);
       
        
      },[form]);


    //체크박스 상태 관리
    const [checkedList, setCheckedList] = useState([]);
     
    const onCheckBoxChange = useCallback(e => {
        const checkboxes = document.getElementsByName(`${e.target.name}`)

        for (let i=0; i< checkboxes.length; i++){
            

            if(checkboxes[i] !== e.target){
                checkboxes[i].checked = false
                
            }

            
        }

        if(e.target.value == "1" && e.target.checked){
            console.log("동의 ")
            setCheckedList([...checkedList, e.target.name]);
        }else {
            console.log("미동의")
            setCheckedList(checkedList.filter(el => el !== e.target.name));
            //console.log("미동의")
        }

        console.log(checkedList)

        

      
       
        
      },[checkedList]);



    const AuthCheck = useCallback(async() =>{
        //console.log(form)
        //console.log("본인인증번호전송")
        //console.log(form.phoneNumber.length)
        if(Number(form.phoneNumber.length) < 10 || Number(form.userName.length) <2 || form.userName == null || form.phoneNumber == null){
            alert("이름 또는 번호를 확인해주세요")
            return
        }
      

        try{
            const fetchData = await axios.get(
                //`${process.env.REACT_APP_HOST}/education`,{
                //`http://localhost:3002/sens/sms?name=${userName}&content=${content}&phoneNumber=${phoneNumber}`,{
                   `${process.env.REACT_APP_HOST}/sens/sms?userName=${form.userName}&phoneNumber=${form.phoneNumber}`,{

                },
                {
                    headers:{
                        token:process.env.REACT_APP_ACCESS_WEDO_TOKEN
                    }
                }
            );

            
            
            
            //console.log(fetchData.data)
            setReceivedAuthNumber(fetchData.data)
            setSendOk(true)
            alert("전송되었습니다.")

            //setEducationGetList(getData.data.educationwrite)
            //setRefEducationGetList(getData.data.educationwrite)
            
            //window.location.reload()
            //fetchWedoNotificationData();
            
            
    
        }catch (e) {
            //console.log(e);
           
        }
   


    },);
    
    const Submit = useCallback(async() => {
        if(form.userName == null || form.userName == "" || form.phoneNumber == null ||
        form.phoneNumber == "" || form.authNumber == null || form.authNumber ==""){
            alert("빈값이 존재합니다.")
            return;
        }

        if(checkedList.length <3){
            alert("동의 / 미동의 확인해주세요")
            return;
        }


        if(window.confirm("제출할까요?")){
            //console.log("제출하기");
            //console.log(form);
            //console.log(receivedAuthNumber);
            //console.log(checkedList);
            if(form.authNumber != receivedAuthNumber){
                alert("인증번호가 다릅니다.")
                return;
            }else{

                try{
                    const fetchData = await axios.post(
                        //`${process.env.REACT_APP_HOST}/education`,{
                        //`http://localhost:3002/sens/sms?name=${userName}&content=${content}&phoneNumber=${phoneNumber}`,{
                            `${process.env.REACT_APP_HOST}/sens/sms?location=${refLocation}&section=${section}&userName=${form.userName}&phoneNumber=${form.phoneNumber}&checkList=${checkedList.toString()}`,{
        
                        },
                        {
                            headers:{
                                token:process.env.REACT_APP_ACCESS_WEDO_TOKEN
                            }
                        }
                    );
        
                    
                    
                    
                    //console.log(fetchData.data)
                    alert("제출되었습니다.")
                    window.location.reload()
                    
            
                }catch (e) {
                    //console.log(e);
                   
                }




                
            }
            
            

        }else{
            alert("취소되었습니다.")
        }
        
    },)
   
        

    return(
            <>
              
                    <Block>
                 
                
                    <div >
                        <h2>롯데엠시시 정보 제공 동의서</h2>

                        <table>
                            <thead>
                                <tr>
                                    <th colSpan="2">
                                        <h3>인증 부분</h3>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        이름
                                    </td>
                                    <td>
                                        <input type="text" name='userName' onChange={onChange} placeholder='Name'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        휴대전화
                                    </td>
                                    <td>
                                        <input type="tel" name='phoneNumber' onChange={onChange} placeholder='- 없이 입력해주세요'/>
                                        <button className='btnAuth' onClick={AuthCheck}>본인인증</button>
                                    </td>
                                </tr>
                                {sendOk == true && <>
                                    <tr>
                                    <td>
                                        인증번호
                                    </td>
                                    <td>
                                        <input type="text" name='authNumber' onChange={onChange} placeholder='인증번호입력란'/>
                                        메시지를 확인해주세요!
                                    </td>
                                    </tr>
                                
                                
                                
                                </>}
                                
                            </tbody>
                        </table>

                        <hr/>

                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={3}>
                                       
                                        <h3>개인정보 수집 이용 제3자 제공 동의서 </h3>
                                    </th>
                                </tr>
                                
                            </thead>
                            <tbody>
                                <tr>
                                <td colSpan={3}>
                                    {section == "safety" ? <>
                                        본인은 "롯데엠시시 주식회사(이하'회사'라 함) {location}공장에 출입함에 있어, 회사가 출입관리 및 신원확인을 위해 개인정보의 수집 이용이
                                        필요하다는 것을 이해하고 있으며, 이를 "개인정보보호법"등 관련 규정에 따라 개인정보를 수집 이용하는것에 아래와
                                        같이 동의합니다.
                                    </> : <>
                                        롯데엠시시 주식회사(이하'회사'라 함) 귀하의 위험물차량 운송자 자격 확인을 위하여 위험물 운송차량 및 해당 운전자에 대한
                                        정보를 수집/이용 및 제 3자에게 제공하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결하여 주십시요
                                    </>}
                                        
                                    </td>
                                
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                       1. 개인정보 수집/이용 동의
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        항목
                                    </td>
                                    <td>
                                        수집/이용 목적
                                    </td>
                                    <td>
                                        보유/이용 기간
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {section == "safety" ? <>
                                        성명, 생년월일, 주소,  
                                        소속회사명, 전화번호, 
                                        차량정보(차종/번호/정기검사/보험내역) 
                                        자동차운전면허증, 각종 교육/자격 이수증 및 면허증
                                        </> : <>
                                        성명, 생년월일, 주소, 연락처, 
                                        차량정보(차종, 차명, 형식 및 모델연도, 차대번호, 자동차 등록번호)
                                        화물운송종사자 자격증번호, 위험물운송자 자격번호 등 자동차 등록증, 
                                        자동차운전면허증, 화물운송 종사자격증, 위험물운송자증, 위험물차량 안전점검표에 기재되어 잇는 내용
                                        
                                        </>}

                                      
                                        
                                    </td>
                                    <td>
                                        {section=="safety" && <>
                                        1) {location}공장 출입자 신원확인 <br/>
                                           
                                        2) {location}공장 출입 인원수(현황) 확인 <br/>
                                           
                                        3) {location}공장 교육/검진/작업관련 신원확인 <br/>
                                        
                                        </>}

                                        {section=="pmma" && <>
                                        여수공장 출하 안전교육
                                        
                                        </>}

                                        {section=="mma" && <>
                                        위험물차량 운송자 자격확인
                                        
                                        </>}

                                        
                                        
                                        
                                        
                                    </td>
                                    <td>
                                        {section=="safety" && <>
                                        수집일로부터 최대 3년 
                                        
                                        </>}
                                        
                                        {section=="pmma" && <>
                                        최대 1년(안전교육 갱신 기준)
                                        
                                        </>}

                                        {section=="mma" && <>
                                        화물차량으로 회사 공장에 출입하는 기간 동안 
                                        
                                        </>}


                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3">
                                        *위 개인정보 수집/이용 동의를 거부할 권리가 있습니다. 
                                        그러나,동의를 거부할 경우 회사 공장 내 출입이 제한될 수 있습니다.

                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3">
                                        * 위와 같이 개인정보를 수집/이용하는데 동의하십니까?<br/>
                                        &emsp;&emsp;
                                        동의 <input type="checkbox" name="agree1" value="1" onChange={
                                            onCheckBoxChange
                                        }  /> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                        미동의 <input type="checkbox" name="agree1" value="2" onChange={
                                        onCheckBoxChange
                                        }  /> 
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                       2. 고유식별정보 수집/이용 동의
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        항목
                                    </td>
                                    <td>
                                        수집/이용 목적
                                    </td>
                                    <td>
                                        보유/이용 기간
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {section == "safety" && <>
                                        운전면허번호
                                        </>}
                                        {section == "pmma" && <>
                                        운송사 안전교육 사항
                                        </>}
                                        {section == "mma" && <>
                                        운전면허번호
                                        </>}
                                    </td>
                                    <td>
                                        {section == "safety" && <>
                                        1) {location}공장 출입자 신원확인 
                                        </>}
                                        {section == "pmma" && <>
                                        운송자 안전교육 여부 확인
                                        </>}
                                        {section == "mma" && <>
                                        위험물차량 운송자 자격확인 
                                        </>}
                                        
                                           
                                    </td>
                                    <td>
                                        
                                        {section == "safety" && <>
                                        수집일로부터 최대 3년
                                        </>}
                                        {section == "pmma" && <>
                                        최대 1년 (안전교육 갱신 기준)
                                        </>}
                                        {section == "mma" && <>
                                        화물차량으로 회사 공장에 출입하는 기간 동안 
                                        </>}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3">
                                        *위 개인정보 수집/이용 동의를 거부할 권리가 있습니다. 
                                        그러나,동의를 거부할 경우 회사 공장 내 출입이 제한될 수 있습니다.

                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3">
                                        * 위와 같이 개인정보를 수집/이용하는데 동의하십니까?<br/>
                                        &emsp;&emsp;
                                        동의 <input type="checkbox" name="agree2" value="1" onChange={
                                            onCheckBoxChange
                                        }  /> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                        미동의 <input type="checkbox" name="agree2" value="2" onChange={
                                        onCheckBoxChange
                                        }  /> 
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={3}>
                                       3. 개인정보 제 3자 제공 내역
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        항목
                                    </td>
                                    <td>
                                        수집/이용 목적
                                    </td>
                                    <td>
                                        제공 항목
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    {section == "safety" && <>
                                        롯데케미칼(주)<br/>
                                        주식회사 씨텍<br/>
                                        서산화학재난합동방지센터
                                        </>}
                                        {section == "pmma" && <>
                                        롯데케미칼(주) <br/>
                                        안전보건 공단 점검
                                        </>}
                                        {section == "mma" && <>
                                        한국소방안전원
                                        </>}
                                    
                                    </td>
                                    <td>
                                        {section == "safety" && <>
                                        1) {location}공장 출입자 신원확인 <br/>
                                        2) 교육 관련 신청 및 신원 확인<br/>
                                        3) 유관 기간 제출
                                        </>}

                                        {section == "pmma" && <>
                                        운송사 안전교육 여부 확인
                                        </>}

                                        {section == "mma" && <>
                                        위험물차량 운송자 자격확인
                                        </>}
                                        
                                           
                                    </td>
                                    <td>
                                        {section == "safety" && <>
                                        성명, 생년월일, 주소,  
                                        소속회사명, 전화번호, 
                                        차량정보(차종/번호/정기검사/보험내역) 
                                        자동차운전면허증
                                        </>}

                                        {section == "pmma" && <>
                                        성명, 생년월일, 주소, 연락처, 차량정보, 운전면허번호
                                        </>}

                                        {section == "mma" && <>
                                        성명, 생년월일, 주소, 연락처, 차량정보, 운전면허번호, 화물운송종사자 자격증번호, 위험물운송자 자격번호
                                        </>}
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3">
                                        *위 개인정보 수집/이용 동의를 거부할 권리가 있습니다. 
                                        그러나,동의를 거부할 경우 회사 공장 내 출입이 제한될 수 있습니다.

                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3">
                                        * 위와 같이 개인정보를 수집/이용하는데 동의하십니까?<br/>
                                        &emsp;&emsp;
                                        동의 <input type="checkbox" name="agree3" value="1" onChange={
                                            onCheckBoxChange
                                        }  /> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                        미동의 <input type="checkbox" name="agree3" value="2" onChange={
                                        onCheckBoxChange
                                        }  /> 
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3">
                                        <h3>롯데엠시시 주식회사 대표이사 귀중</h3>
                                    </td>
                                </tr>

                                

                            </tbody>
                        </table>
                            <button className='btnSubmit' onClick={Submit}>제출하기</button>
                    </div>
                
                
             
                 
                 </Block>

                             
              
            </>
    
        );
    
   
};

export default index