import React, { PureComponent } from 'react';
import { useState, useCallback, useEffect, useRef} from 'react';


import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';


import styled from 'styled-components'
import axios from 'axios';

import SignatureCanvas from 'react-signature-canvas'
import {Buffer} from 'buffer'
import Modal from 'react-modal'
import {v4} from 'uuid'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"


import './Paging.css'
import Pagination from 'react-js-pagination';
import Write from './Write';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



const Block = styled.div`
  overflow:auto;

  th, td {
    border: 1px solid lightgrey;
    padding: 3px;
    text-align: center ;
  }

`


const customStyles ={
  content: {
      top:'50%',
      left:'50%',
      right:'auto',
      bottom:'auto',
      marginRight:'-50%',
      transform: 'translate(-50%, -50%)',
      
  },
  border:{
    border: 'solid'
  }
  

 
  
}



Modal.setAppElement('#root')
const GroundingCheckWrite = ({_id, location, refCategory}) => {
  const [datas, setDatas] =useState(null)
  const [refDatas, setRefDatas] =useState(null)

  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies(['auth']);
  const [modalIsOpen, setIsOpen] = useState(false);
  const signCanvas = useRef() 
  const componenetRef = useRef();
  

    //console.log(match.params.location)
    //const data = match.params
   
    //const location = data.location
    
    const [category, setCategory] = useState('elec')
    const [writeOn,setWriteOn] = useState(false)
    const [idInform,setIdInform] = useState(null)
    const [login, setLogin] = useState(true)

    const [ inChargePerson, setInChargePerson ] = useState('')
    const [startDate, setStartDate] = useState(new Date());
    const [selectDate, setSelectDate] = useState(null);
    const [endDate, setEndDate] = useState(new Date());
    const [signPath1, setSignPath1] = useState(null)
    const [signPath2, setSignPath2] = useState(null)
    const [signPath3, setSignPath3] = useState(null)
    const [detailView, setDetailView] = useState(false)


    const [filterOn, setFilterOn] = useState(false)
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pagelimit, setPagelimit] = useState(10);
    const handlePageChange = page => {
        
      setFilterOn(false)
      setPage(page)
      setDatas(refDatas.slice((page-1)*pagelimit,(page*pagelimit)))
               
  }

  const [form, setForm] = useState ({
    

  })

  const onChangeComment= useCallback((name,comment) => {
    
    //console.log(name)
    const nextForm = {
      ...form,
      [name]: comment
    };

    
    setForm(nextForm);
   
    console.log(form)
    
},[form]);


  const today =(value) => {
    const year = value.getFullYear();
    const month = ('0' +(value.getMonth()+1)).slice(-2);
    const day = ('0' + value.getDate()).slice(-2);
    const dateString = year + '-' + month +'-' + day;

    return dateString
  }

  const dateFilter = useCallback(() => {
    const filterData = refDatas.filter(refData => {
      //console.log(refData.createdAt)
      //if(today( new Date(new Date(refData.createdAt) - 32400000)) >= today(startDate) && today(new Date(new Date(refData.createdAt) - 32400000)) <= today(endDate)){
      if(today( new Date(refData.createdAt)) >= today(startDate) && today(new Date(refData.createdAt)) <= today(endDate)){
        return true
      }
    })
    console.log(startDate)
    
    console.log(filterData)
    setDatas(filterData)
    setFilterOn(true);
  })

  

    const [ tableIndex, setTableIndex ] = useState(null)

 

    const onChange = useCallback(e => {
    
    
      const nextForm = {
        ...form,
        [e.target.name]: e.target.value
      };

      
      setForm(nextForm);
     
      
  },[form]);

  useEffect(() => {
    //console.log(cookies.auth)
    if (_id != null){
      //쓰기가 아닐경우 개별 아이디 조회 
      fetchGetData(_id);
      

    }
  },[])

  



  const fetchGetData = async(_id) => {

    try{
      
      const response = await axios.get(
        `${process.env.REACT_APP_SOCKET}/plantdata/detail?id=${_id}`,{
            headers:{
                token : cookie.auth
            }
          }
      );

      setDatas(response.data[0])
      setRefDatas(response.data[0])
      setTotalCount(response.data[0].length)
      setSelectDate(new Date(response.data[0].createdAt))
      setForm(response.data[0].dataList[0])
      console.log(response.data[0])

       


    }catch (e) {
        //console.log(e);

       
    }
  }


  const fetchLoginCheck = async() => {

    try{
      
        const response = await axios.get(
            `${process.env.REACT_APP_HOST_RPA}/rpa/account/test`,{
                headers:{
                    token : cookie.auth
                }
            }
        );
        
            //console.log(response.data)
        
        if(response.data.code == 419 || response.data.code == 401)
        {
            //alert("토큰만료")
            history.push("/login")
        }

        //


    }catch (e) {
        //console.log(e);

        history.push("/login")
       
    }
  }



  

  const doneWrite = async() => {
    //alert("작성되었습니다.")
    
    try{
      
      const response = await axios.post(
        `${process.env.REACT_APP_SOCKET}/plantdata?type=${refCategory}&date=${startDate}&location=${location}`,{
            dataList : form,
            headers:{
                'token': cookie.auth
            }
        });
        alert("등록되었습니다.")
        
        window.location.reload();
        
        //form.clear()
        
    
      //console.log(response.data.length)

     


    }catch (e) {
        console.log(e);
        alert(e)
      
    }
   
    
}
//서명 클리어
const clear = () => {
    //캔버스 클리어
    signCanvas.current.clear();
}
//서명 저장
const save = () => {
    
    console.log("캔버스 저장")
    
    const dataURL = signCanvas.current.toDataURL('image/png')
    const decodeURL = dataURL.replace(/^data:image\/\w+;base64,/,'');
    const buf = Buffer.from(decodeURL, 'base64')
    const blob = new Blob([buf], {type:'image/png'})
    const file = new File([blob], `${v4()}.png`,{type:'image/png'})
    console.log(file)
    
    const formData = new FormData();
    formData.append('sign', file)

    /*
    axios({
        baseURL : process.env.REACT_APP_HOST,
        url: '/pm/uploadsignimg',
        method: 'POST',
        data: formData,
        headers:{
            'Content-Type' : 'multipart/form-data',              
        },
    })
        .then(response => {
            //console.log(response.data)
            //console.log(response.data.originalname)
            
            //setReturnOriginalFilename(response.data.originalname)
            console.log(response.data.filename)
            if(inChargePerson == '담당자'){
              setSignPath1(response.data.filename)
            }else if(inChargePerson == '책임'){
              setSignPath2(response.data.filename)
            }else if(inChargePerson == '리더'){
              setSignPath3(response.data.filename)
            }
            //enrollResult(response.data.filename)

                           
            

        })
        .catch(error => {
            console.error(error)
        })

    */
   
    
}

const doneMody = async() => {
  //alert("작성되었습니다.")
  
  
  
  if(window.confirm("수정할까요?")){
      
   
           //캔버스 저장 및 평가 결과 
          //save()           

        try{
    
            const response = await axios.put(
              `${process.env.REACT_APP_SOCKET}/plantdata/detail?id=${_id}&type=${refCategory}&date=${selectDate - 32400000 }&location=${location}`,{
                  dataList : form,
                  headers:{
                      'token': cookie.auth
                  }
              });
              alert("수정되었습니다..")
              
              window.location.reload();
              
              //form.clear()
              
          
            //console.log(response.data.length)
    
           
    
    
        }catch (e) {
            console.log(e);
            alert(e)
           
        }

          //fetchData()
          

          //setIsOpen(false)
          //fetchGetData()
          
          
          
  
  }else{
      //취소
      return false
  }
 
  
}



  const openModal = async(refPerson) => {
    setIsOpen(!modalIsOpen)
    
    console.log(refPerson)
    setInChargePerson(refPerson)

    
    
}



const afterOpenModal = () => {
   
}

const closeModal = () => {
    setIsOpen(false)
    
 
    console.log("close")
}



const table = (data) => {
  const locationList = ["Tank Yard", "Tank Yard", "Tank Yard", "#4/5000 Unit", "#4/5000 Unit", "#6000 Unit", "#6000 Unit", "#2/3000 Unit", "#2/3000 Unit", "#2/3000 Unit", "#2/3000 Unit", 
  "#1000 Unit", "#1000 Unit", "Catalyst", "Catalyst", "Product Loading", "CCR/SS", "CCR/SS", "Cooling Tower", "Cooling Tower", "WWT Area",
  "CCR/SS","CCR/SS","CCR/SS","CCR/SS","CCR/SS","CCR/SS","CCR/SS","CCR/SS","CCR/SS","CCR/SS",
  "CCR/SS","CCR/SS","CCR/SS","CCR/SS","CCR/SS","CCR/SS","CCR/SS","CCR/SS","Tank Yard","Tank Yard",
  "#4/5000 Unit","#4/5000 Unit","#4/5000 Unit","#4/5000 Unit","#4/5000 Unit","#4/5000 Unit", "#6000 Unit","#6000 Unit","#2/3000 Unit","#2/3000 Unit","#2/3000 Unit",
  "Cooling Tower","Cooling Tower","Cooling Tower","Cooling Tower","Tank Yard","Tank Yard","Tank Yard","Tank Yard","Tank Yard",]
  const textHtml= []

  if (data ==null ){
    
      textHtml.push(
      <tr style={{height:'40px'}}>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-01"}/> </td>
        <td> <input type='text' value={"Tank Yard"}/> </td>
        <td> <input type='text' name={'sub11'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub12'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub13'} onChange={onChange}/> </td>
      </tr>)

      textHtml.push(
      <tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-02"}/> </td>
        <td> <input type='text' value={"Tank Yard"}/> </td>
        <td> <input type='text' name={'sub21'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub22'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub23'} onChange={onChange}/> </td>
        </tr>)
      textHtml.push(
      <tr>  
        <td> LOOP </td>
        <td> <input type='text' value={"EP-02-1"}/> </td>
        <td> <input type='text' value={"Tank Yard"}/> </td>
        <td> <input type='text' name={'sub2111'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub2112'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub2113'} value={"HT-2610 & T-9250C (증설 TK)"} onChange={onChange}/> </td>
      </tr> )
      textHtml.push(
      <tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-03"}/> </td>
        <td> <input type='text' value={"#4/5000 Unit"}/> </td>
        <td> <input type='text' name={'sub31'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub32'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub33'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-04"}/> </td>
        <td> <input type='text' value={"#4/5000 Unit"}/> </td>
        <td> <input type='text' name={'sub41'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub42'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub43'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-05"}/> </td>
        <td> <input type='text' value={"#6000 Unit"}/> </td>
        <td> <input type='text' name={'sub51'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub52'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub53'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-06"}/> </td>
        <td> <input type='text' value={"#6000 Unit"}/> </td>
        <td> <input type='text' name={'sub61'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub62'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub63'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-07"}/> </td>
        <td> <input type='text' value={"#2/3000 Unit"}/> </td>
        <td> <input type='text' name={'sub61'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub62'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub63'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-08"}/> </td>
        <td> <input type='text' value={"#2/3000 Unit"}/> </td>
        <td> <input type='text' name={'sub81'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub82'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub83'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-09"}/> </td>
        <td> <input type='text' value={"#2/3000 Unit"}/> </td>
        <td> <input type='text' name={'sub91'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub92'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub93'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-10"}/> </td>
        <td> <input type='text' value={"#2/3000 Unit"}/> </td>
        <td> <input type='text' name={'sub101'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub102'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub103'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-11"}/> </td>
        <td> <input type='text' value={"#1000 Unit"}/> </td>
        <td> <input type='text' name={'sub111'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub112'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub113'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-12"}/> </td>
        <td> <input type='text' value={"#1000 Unit"}/> </td>
        <td> <input type='text' name={'sub121'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub122'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub123'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-13"}/> </td>
        <td> <input type='text' value={"#Catalyst"}/> </td>
        <td> <input type='text' name={'sub131'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub132'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub133'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-14"}/> </td>
        <td> <input type='text' value={"#Catalyst"}/> </td>
        <td> <input type='text' name={'sub141'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub142'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub143'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-15"}/> </td>
        <td> <input type='text' value={"Product Loading"}/> </td>
        <td> <input type='text' name={'sub151'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub152'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub153'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-16"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub161'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub162'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub163'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-17"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub171'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub172'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub173'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-18"}/> </td>
        <td> <input type='text' value={"Cooling Tower"}/> </td>
        <td> <input type='text' name={'sub181'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub182'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub183'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-19"}/> </td>
        <td> <input type='text' value={"Cooling Tower"}/> </td>
        <td> <input type='text' name={'sub191'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub192'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub193'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> LOOP </td>
        <td> <input type='text' value={"EP-20"}/> </td>
        <td> <input type='text' value={"WWT Area"}/> </td>
        <td> <input type='text' name={'sub201'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub202'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub203'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Inst </td>
        <td> <input type='text' value={"EP-21"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub211'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub212'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub213'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Inst </td>
        <td> <input type='text' value={"EP-22"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub221'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub222'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub223'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Inst </td>
        <td> <input type='text' value={"EP-23"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub231'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub232'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub233'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Inst </td>
        <td> <input type='text' value={"EP-24"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub241'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub242'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub243'} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Equip </td>
        <td> <input type='text' value={"EP-25"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub251'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub252'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub253'} value={"UPS & UPS Battery"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Equip </td>
        <td> <input type='text' value={"EP-26"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub261'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub262'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub263'} value={"B/C & B/C Battery"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Equip </td>
        <td> <input type='text' value={"EP-27"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub271'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub272'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub273'} value={"K-6104 (VVVF)"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Equip </td>
        <td> <input type='text' value={"EP-28"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub281'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub282'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub283'} value={"HRG-N"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Equip </td>
        <td> <input type='text' value={"EP-29"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub291'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub292'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub293'} value={"HRG-N"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Equip </td>
        <td> <input type='text' value={"EP-30"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub301'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub302'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub303'} value={"HRG-N"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Equip </td>
        <td> <input type='text' value={"EP-31"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub311'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub312'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub313'} value={"HRG-N"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Equip </td>
        <td> <input type='text' value={"EP-32"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub321'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub322'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub323'} value={"HRG-N"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Equip </td>
        <td> <input type='text' value={"EP-33"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub331'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub332'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub333'} value={"NRG-N"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Equip </td>
        <td> <input type='text' value={"EP-34"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub341'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub342'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub343'} value={"D/G-N"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Equip </td>
        <td> <input type='text' value={"EP-35"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub351'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub352'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub353'} value={"HVAC-N"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Equip </td>
        <td> <input type='text' value={"EP-36"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub361'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub362'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub363'} value={"ELP-01"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Equip </td>
        <td> <input type='text' value={"EP-37"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub371'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub372'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub373'} value={"MDB-01"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-38"}/> </td>
        <td> <input type='text' value={"CCR/SS"}/> </td>
        <td> <input type='text' name={'sub381'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub382'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub383'} value={"MDB-02"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-55"}/> </td>
        <td> <input type='text' value={"Tank Yard"}/> </td>
        <td> <input type='text' name={'sub551'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub552'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub553'} value={"T-9100A(N)"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-56"}/> </td>
        <td> <input type='text' value={"Tank Yard"}/> </td>
        <td> <input type='text' name={'sub561'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub562'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub563'} value={"T-9100A(S)"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-57"}/> </td>
        <td> <input type='text' value={"Tank Yard"}/> </td>
        <td> <input type='text' name={'sub571'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub572'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub573'} value={"T-9200A(N)"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-58"}/> </td>
        <td> <input type='text' value={"Tank Yard"}/> </td>
        <td> <input type='text' name={'sub581'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub582'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub583'} value={"T-9200A(S)"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-59"}/> </td>
        <td> <input type='text' value={"Tank Yard"}/> </td>
        <td> <input type='text' name={'sub591'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub592'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub593'} value={"T-9250C(W)(증성Tk)"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-39"}/> </td>
        <td> <input type='text' value={"Tank Yard"}/> </td>
        <td> <input type='text' name={'sub391'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub392'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub393'} value={"T-9100B(S)"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-40"}/> </td>
        <td> <input type='text' value={"Tank Yard"}/> </td>
        <td> <input type='text' name={'sub401'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub402'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub403'} value={"T-T-9205B(N)"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-41"}/> </td>
        <td> <input type='text' value={"#4/5000 Unit"}/> </td>
        <td> <input type='text' name={'sub411'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub412'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub413'} value={"T-4550"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-42"}/> </td>
        <td> <input type='text' value={"#4/5000 Unit"}/> </td>
        <td> <input type='text' name={'sub421'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub422'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub423'} value={"C-5500"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-43"}/> </td>
        <td> <input type='text' value={"#4/5000 Unit"}/> </td>
        <td> <input type='text' name={'sub431'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub432'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub433'} value={"C-4400"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-44"}/> </td>
        <td> <input type='text' value={"#4/5000 Unit"}/> </td>
        <td> <input type='text' name={'sub441'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub442'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub443'} value={"C-4100"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-45"}/> </td>
        <td> <input type='text' value={"#4/5000 Unit"}/> </td>
        <td> <input type='text' name={'sub451'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub452'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub453'} value={"C-5300"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-46"}/> </td>
        <td> <input type='text' value={"#6000 Unit"}/> </td>
        <td> <input type='text' name={'sub461'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub462'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub463'} value={"R-6100"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-47"}/> </td>
        <td> <input type='text' value={"#6000 Unit"}/> </td>
        <td> <input type='text' name={'sub471'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub472'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub473'} value={"C-6120"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-48"}/> </td>
        <td> <input type='text' value={"#2/3000 Unit"}/> </td>
        <td> <input type='text' name={'sub481'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub482'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub483'} value={"C-3500"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-49"}/> </td>
        <td> <input type='text' value={"#2/3000 Unit"}/> </td>
        <td> <input type='text' name={'sub491'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub492'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub493'} value={"C-2210"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-50"}/> </td>
        <td> <input type='text' value={"#2/3000 Unit"}/> </td>
        <td> <input type='text' name={'sub501'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub502'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub503'} value={"R-3100A"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-51"}/> </td>
        <td> <input type='text' value={"Cooling Tower"}/> </td>
        <td> <input type='text' name={'sub511'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub512'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub513'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-52"}/> </td>
        <td> <input type='text' value={"Cooling Tower"}/> </td>
        <td> <input type='text' name={'sub521'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub522'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub523'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-53"}/> </td>
        <td> <input type='text' value={"Cooling Tower"}/> </td>
        <td> <input type='text' name={'sub531'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub532'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub533'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-54"}/> </td>
        <td> <input type='text' value={"Cooling Tower"}/> </td>
        <td> <input type='text' name={'sub541'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub542'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub543'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Ground Reel For Car </td>
        <td> <input type='text' value={"1"}/> </td>
        <td> <input type='text' value={"P-9102"}/> </td>
        <td> <input type='text' name={'subG11'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subG12'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subG13'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Ground Reel For Car </td>
        <td> <input type='text' value={"2"}/> </td>
        <td> <input type='text' value={"P-8501"}/> </td>
        <td> <input type='text' name={'subG21'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subG22'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subG23'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Ground Reel For Car </td>
        <td> <input type='text' value={"3"}/> </td>
        <td> <input type='text' value={"P-9006"}/> </td>
        <td> <input type='text' name={'subG31'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subG32'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subG33'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Ground Reel For Car </td>
        <td> <input type='text' value={"4"}/> </td>
        <td> <input type='text' value={"P-9252"}/> </td>
        <td> <input type='text' name={'subG41'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subG42'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subG43'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Ground Reel For Car </td>
        <td> <input type='text' value={"5"}/> </td>
        <td> <input type='text' value={"E-5032"}/> </td>
        <td> <input type='text' name={'subG51'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subG52'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subG53'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Ground Reel For Car </td>
        <td> <input type='text' value={"6"}/> </td>
        <td> <input type='text' value={"P-0141"}/> </td>
        <td> <input type='text' name={'subG61'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subG62'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subG63'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Ground Reel For Car </td>
        <td> <input type='text' value={"7"}/> </td>
        <td> <input type='text' value={"D-7720"}/> </td>
        <td> <input type='text' name={'subG71'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subG72'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subG73'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Ground Reel For Car </td>
        <td> <input type='text' value={"8"}/> </td>
        <td> <input type='text' value={"T-9800"}/> </td>
        <td> <input type='text' name={'subG81'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subG82'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subG83'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Ground Reel For Car </td>
        <td> <input type='text' value={"9"}/> </td>
        <td> <input type='text' value={"Product Loading House"}/> </td>
        <td> <input type='text' name={'subG91'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subG92'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subG93'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Ground Reel For Car </td>
        <td> <input type='text' value={"10"}/> </td>
        <td> <input type='text' value={"Product Loading House"}/> </td>
        <td> <input type='text' name={'subG101'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subG102'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subG103'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Ground Reel For Car </td>
        <td> <input type='text' value={"11"}/> </td>
        <td> <input type='text' value={"Product Loading House"}/> </td>
        <td> <input type='text' name={'subG111'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subG112'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subG113'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Ground Reel For Car </td>
        <td> <input type='text' value={"12"}/> </td>
        <td> <input type='text' value={"Product Loading House"}/> </td>
        <td> <input type='text' name={'subG121'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subG122'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subG123'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Ground Reel For Car </td>
        <td> <input type='text' value={"13"}/> </td>
        <td> <input type='text' value={"HP-2231A"}/> </td>
        <td> <input type='text' name={'subG131'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subG132'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subG133'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Ground Reel For Car </td>
        <td> <input type='text' value={"14"}/> </td>
        <td> <input type='text' value={"HD-2483"}/> </td>
        <td> <input type='text' name={'subG141'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subG142'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subG143'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Ground Reel For Car </td>
        <td> <input type='text' value={"15"}/> </td>
        <td> <input type='text' value={"HD-2480"}/> </td>
        <td> <input type='text' name={'subG151'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subG152'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subG153'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Ground Reel For Car </td>
        <td> <input type='text' value={"16"}/> </td>
        <td> <input type='text' value={"E.O TK"}/> </td>
        <td> <input type='text' name={'subG161'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subG162'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subG163'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> HEMA </td>
        <td> <input type='text' value={"HEP-21"}/> </td>
        <td> <input type='text' value={"LOOP"}/> </td>
        <td> <input type='text' name={'subH211'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subH212'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subH213'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> HEMA </td>
        <td> <input type='text' value={"HEP-22"}/> </td>
        <td> <input type='text' value={"LOOP"}/> </td>
        <td> <input type='text' name={'subH221'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subH222'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subH223'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> HEMA </td>
        <td> <input type='text' value={"HEP-57"}/> </td>
        <td> <input type='text' value={"Lightning"}/> </td>
        <td> <input type='text' name={'subH571'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subH572'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subH573'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> HEMA </td>
        <td> <input type='text' value={"HEP-58"}/> </td>
        <td> <input type='text' value={"Lightning"}/> </td>
        <td> <input type='text' name={'subH581'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subH582'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subH583'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> HEMA </td>
        <td> <input type='text' value={"HEP-59"}/> </td>
        <td> <input type='text' value={"Lightning"}/> </td>
        <td> <input type='text' name={'subH591'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subH592'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subH593'}  onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> HEMA </td>
        <td> <input type='text' value={"HEP-65"}/> </td>
        <td> <input type='text' value={"Lightning"}/> </td>
        <td> <input type='text' name={'subH651'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subH652'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subH653'}  value={"HT-2610 (증설 TK)"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> HEMA </td>
        <td> <input type='text' value={"HEP-60"}/> </td>
        <td> <input type='text' value={"LOOP"}/> </td>
        <td> <input type='text' name={'subH601'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subH602'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subH603'}  value={"HEMA 옥내저장소"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> HEMA </td>
        <td> <input type='text' value={"HEP-61"}/> </td>
        <td> <input type='text' value={"LOOP"}/> </td>
        <td> <input type='text' name={'subH611'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subH612'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subH613'}  value={"HEMA 옥내저장소"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> HEMA </td>
        <td> <input type='text' value={"HEP-62"}/> </td>
        <td> <input type='text' value={"LOOP"}/> </td>
        <td> <input type='text' name={'subH621'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subH622'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subH623'}  value={"Equipment Washing Facility"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> HEMA </td>
        <td> <input type='text' value={"HEP-63"}/> </td>
        <td> <input type='text' value={"LOOP"}/> </td>
        <td> <input type='text' name={'subH631'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subH632'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subH633'}  value={"Equipment Washing Facility"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"HEP-60"}/> </td>
        <td> <input type='text' value={"Lightning"}/> </td>
        <td> <input type='text' name={'subH601'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'subH602'} onChange={onChange}/> </td>
        <td> <input type='text' name={'subH603'}  value={"HEMA 옥내저장소"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-60"}/> </td>
        <td> <input type='text' value={"#4/5000 Unit"}/> </td>
        <td> <input type='text' name={'sub601'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub602'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub603'} value={"EP-41 증설, T-4550"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-61"}/> </td>
        <td> <input type='text' value={"#6000 Unit"}/> </td>
        <td> <input type='text' name={'sub611'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub612'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub613'} value={"EP-47 증설, C-46120"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-62"}/> </td>
        <td> <input type='text' value={"Tank Yard"}/> </td>
        <td> <input type='text' name={'sub621'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub622'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub623'} value={"EP-56,39,40 증설"} onChange={onChange}/> </td>
      </tr>)
      textHtml.push(<tr>
        <td> Light </td>
        <td> <input type='text' value={"EP-63"}/> </td>
        <td> <input type='text' value={"T-9600"}/> </td>
        <td> <input type='text' name={'sub631'} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub632'} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub633'} value={"신설"} onChange={onChange}/> </td>
      </tr>)
        
        
      
    
    return textHtml


  }else{

    for (let i =1; i < 14; i++){
      textHtml.push(<tr style={{height:'40px'}}>
        <td> {i} </td>
        <td> <input type='text' value={"EP-" + i}/> </td>
        <td> <input type='text' value={locationList[i]}/> </td>
        <td> <input type='text' name={'sub'+ i + (i)} defaultValue={data.dataList[0]['sub'+i+(i)]} onChange={onChange}/> </td>
        <td>  <input type='text' name={'sub'+ i + (i+1)} defaultValue={data.dataList[0]['sub'+i+(i+1)]} onChange={onChange}/> </td>
        <td> <input type='text' name={'sub'+ i + (i+2)} defaultValue={data.dataList[0]['sub'+i+(i+2)]} onChange={onChange}/> </td>
        
      
        </tr>)
      
    }
    return textHtml

  }
}

 

return(

  
        <Block>
            
            {_id == null ? <>
              <div>
                    
                    <table>
                      <tr>
                        <td colspan="2" >
                          <div  style={{float:'left'}}>
                            점검주기 : 6 Monthly
                          </div>
                        </td>
                      </tr>
                      
                      <tr>
                        
                        <td colspan='2'> 
                        
                        <div  style={{float:'left'}}>
                          Date : &emsp;&emsp;
                          </div>
                          <div  style={{float:'left'}}>
                          <DatePicker style={{float:'left'}} selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="yy-MM-dd"/>  
                          </div>
                          <div style={{float:'right'}}>
                            점검자 : <input type='text' name='checker' onChange={onChange}></input> &emsp;&emsp;
                          </div>
                          
                        </td>
                        
                        
                      </tr>
                      
                     
                    </table>
                  </div>
              
                  <div>
                    <table>
                      <tr>
                        <th>
                          NO
                        </th>
                        <th>
                          TAG No
                        </th>
                        <th>
                          Location
                        </th>
                        <th>
                          Resistance(Ω)
                        </th>
                        <th>
                          점검결과
                        </th>
                        <th>
                          비고
                        </th>
                      
                        
                      </tr>
                      {table(null)}
                      <tr>
                            <td>특기사항</td>
                            <td colspan='11' style={{textAlign:'left'}} >
                              <CKEditor
                              
                              editor={ ClassicEditor }
                              onChange={(event,editor)=>{
                                  onChangeComment("comment",editor.getData())
                              }}
                              />
                            </td>
                        </tr>
                    </table>
                  </div>




                  <div >
                    
                  <button onClick={() => doneWrite()}>등록하기</button>
                  </div>
            
            </>:<>
            {datas != null && <>
            
            
            <div>
                    
                    <table>
                      <tr>
                        <td colspan="2" >
                          <div  style={{float:'left'}}>
                            점검주기 : 6 Monthly
                          </div>
                        </td>
                      </tr>
                      
                      <tr>
                        
                        <td colspan='2'> 
                        
                        <div  style={{float:'left'}}>
                          Date : &emsp;&emsp;
                          </div>
                          <div  style={{float:'left'}}>
                          <DatePicker style={{float:'left'}} selected={selectDate} onChange={(date) => setStartDate(date)} dateFormat="yy-MM-dd"/>  
                          </div>
                          <div style={{float:'right'}}>
                            점검자 : <input type='text' name='checker' defaultValue={datas.dataList[0].checker} onChange={onChange}></input> &emsp;&emsp;
                          </div>
                          
                        </td>
                        
                        
                      </tr>
                      
                     
                    </table>
                  </div>
              
                  <div>
                    <table>
                      <tr>
                        <th>
                          NO
                        </th>
                        <th>
                          TAG No
                        </th>
                        <th>
                          Location
                        </th>
                        <th>
                          Resistance(Ω)
                        </th>
                        <th>
                          점검결과
                        </th>
                        <th>
                          비고
                        </th>
                      
                        
                      </tr>
                      {table(datas)}
                      <tr>
                            <td>특기사항</td>
                            <td colspan='11' style={{textAlign:'left'}} >
                              <CKEditor
                              
                              editor={ ClassicEditor }
                              onChange={(event,editor)=>{
                                  onChangeComment("comment",editor.getData())
                              }}
                              />
                            </td>
                        </tr>
                    </table>
                  </div>




                  <div >
                    
                    <button onClick={() => doneMody()}>수정하기</button>
                  </div>
                  </>}
            </>}
                  
                
                
                
          
                          
          
        </Block>

    );
    
   
};

export default GroundingCheckWrite