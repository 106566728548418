
import React from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components'
import axios from 'axios';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const Block = styled.div`
    display:flex;
    flex-direction : column;
    font-size: 12px ;
    
    table {
        border-collapse: collapse;
        width : 98%;
    }
  
   
    table, td, th {
        border: 1px solid #ddd;
        text-align: left;
        
    }
    
    tr:nth-child(even){background-color: #f2f2f2}
      
    tr:hover {background-color: #F8F8FF;}

    th  {
        background-color: #6495ED;
        color: white;
        
        
    }

    th, td{
        min-width: 30px ;
        text-align: center;
    }

    .content {
        
    }

    .btnAuth {
        border-radius : 5px;
        width : 80px;
        
    }
   
    .btnSubmit {
        border-radius : 5px;
        width : 100%
        
    }
    
    .center{
        text-align: center ;
    }

   

    button{
        height: 60px;
        background-color: lightcoral ;
        color: white;
        font-size: 20px;
    }

    label{
        font-size: 1rem;
        
    }
    
   
   

`



const Yeosu = ({data}) => {
    
    
    //console.log(location)

    const [startDate, setStartDate] = useState(new Date());

    
    const [form, setForm] = useState ({
        성별 : '',
        나이 : '',
        만족도 : '',
        불합리:'',
        불합리내용:'',
        개선사항 :''

    })


    const onChangeComment= useCallback((name,comment) => {
    
        //console.log(name)
        const nextForm = {
          ...form,
          [name]: comment
        };

        
        setForm(nextForm);
       
        console.log(form)
        
    },[form]);

    const onCheckBoxChange = useCallback(e => {
        const checkboxes = document.getElementsByName(`${e.target.name}`)

        for (let i=0; i< checkboxes.length; i++){
            

            if(checkboxes[i] !== e.target){
                checkboxes[i].checked = false
                //checkedList.splice(i, 1); 
                //i--; 
                
            }
            
        }

        const nextForm = {
            ...form,
            [e.target.name]: e.target.value
          };
  
          
          setForm(nextForm);

        
        console.log(form)


       
        
      },);

    
    const Submit = useCallback(async() => {

        if(form.성별 == null || form.성별 == "" ){
            alert("성별을 확인해주세요")
            return;
        }
        if(form.나이 == null || form.나이 == "" ){
            alert("나이를 확인해주세요")
            return;
        }

        if(form.만족도 == null || form.만족도 == "" ){
            alert("만족도를 확인해주세요")
            return;
        }

        if(form.불합리 == null || form.불합리 == "" ){
            alert("불합리를 확인해주세요")
            return;
        }

        if(window.confirm("제출할까요?")){
         
            try{
                const fetchData = await axios.post(
                    `${process.env.REACT_APP_HOST}/sens/worksurvey?version=${data.ver}&location=${data.location}`,{
                                form : form,
                    },
                    {
                        headers:{
                            //token:process.env.REACT_APP_ACCESS_WEDO_TOKEN
                        }
                    }
                );
    
                
                
                
                console.log(fetchData.data)
                alert("제출되었습니다.")
                window.location.reload()
                
        
            }catch (e) {
                //console.log(e);
                
            }




                
            
            
            

        }else{
            alert("취소되었습니다.")
        }
        
    },)
   
        

    return(
            <>
              
                    <Block>
                 
                
                    <div >
                        <h3></h3>
                        <h2 style={{textDecoration: 'underline', textAlign:'center'}}>롯데엠시시({data.location == "yeosu" ? "여수" : "대산"}) 작업만족도 설문조사</h2>

                        <table>
                            
                               
                                <tr>
                                    <td >
                                        성별
                                    </td>
                                    <td colSpan={2}>
                                        나이(만)
                                        
                                        
                                    </td>
                                    
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" name="성별" value="남" onChange={ onCheckBoxChange } /> 남
                                        <p/>
                                        <input type="checkbox" name="성별" value="여" onChange={ onCheckBoxChange } /> 여
                                    </td>
                                    <td>
                                        <input type="checkbox" name="나이" value="19세이하" onChange={ onCheckBoxChange } /> ~ 19세 이하 
                                        <br/>
                                        <input type="checkbox" name="나이" value="20세~29세" onChange={ onCheckBoxChange } /> 20세~29세
                                        <br/>
                                        <input type="checkbox" name="나이" value="30세~39세" onChange={ onCheckBoxChange } /> 30세~39세
                                        
                                    </td>
                                    <td>
                                        <input type="checkbox" name="나이" value="40세~49세" onChange={ onCheckBoxChange } /> 40세~49세
                                        <br/>
                                        <input type="checkbox" name="나이" value="50세~59세" onChange={ onCheckBoxChange } /> 50세~59세
                                        <br/>
                                        <input type="checkbox" name="나이" value="60세이상" onChange={ onCheckBoxChange } /> 60세 이상 ~
                                        
                                    </td>
                                </tr>
                                
                                
                           
                        </table>
                        <p/>
                        <br/>
                        <label>산업재해가 발생할 급박한 위험이 있는 경우에는 작업을 중지하고 대피 할 수 있습니다. </label>
                        <br/>
                        <p/>
                        <label>작업 중지 및 대피한 후 롯데엠시시 직원에게 알려주시면 됩니다. </label>
                        <p/>
                        <br/>
                        <p/>
                        <label>
                            1. 금번 작업에 대한 전반적인 만족도는 ?
                        </label>
                            <div style={{margin:'20px'}}>
                                <p/>
                                <input type="checkbox" name="만족도" value="매우불만" onChange={ onCheckBoxChange } /> 매우불만
                                <p/>
                                <input type="checkbox" name="만족도" value="불만" onChange={ onCheckBoxChange } /> 불만
                                <p/>
                                <input type="checkbox" name="만족도" value="보통" onChange={ onCheckBoxChange } /> 보통
                                <p/>
                                <input type="checkbox" name="만족도" value="만족" onChange={ onCheckBoxChange } /> 만족
                                <p/>
                            <input type="checkbox" name="만족도" value="매우만족" onChange={ onCheckBoxChange } /> 매우만족
                                

                        </div>
                        

                        <br/>
                        <p/>
                        <br/>
                        <p/>
                        <label>
                            2. 작업 시 불합리한 일을 당한 경험 여부 ?
                        </label>
                        <p/>
                        
                        <div style={{margin:'20px'}}>
                            <input type="checkbox" name="불합리" value="있다" onChange={ onCheckBoxChange } /> 있다
                            <p/>
                            <input type="checkbox" name="불합리" value="없다" onChange={ onCheckBoxChange } /> 없다

                        </div>
                        
                        <br/>
                        <p/>
                        <br/>
                        <label>
                            (있을 시) 구체적으로 기술 부탁드립니다. 
                        </label>
                        <p/>
                        <CKEditor
                            
                            editor={ ClassicEditor }
                            onChange={(event,editor)=>{
                                onChangeComment("불합리내용",editor.getData())
                            }}
                            />

                        
                        <p/>
                        <br/>
                        <p/>
                        <label>
                            3. 개선이 필요한 사항 및 기타 건의사항
                        </label>
                        <p/>
                        <br/>
                        
                        <CKEditor
                            
                            editor={ ClassicEditor }
                            onChange={(event,editor)=>{
                                onChangeComment("개선사항",editor.getData())
                            }}
                            />
                         
                            
                         <p/>
                        <br/>

                        <hr/>

                            <button className='btnSubmit' onClick={Submit}>등록하기</button>
                    </div>
                
                
             
                 
                 </Block>

                             
              
            </>
    
        );
    
   
};

export default Yeosu