import React from 'react';
import { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import Pagination from 'react-js-pagination';
import './Paging.css'
import {useCookies} from 'react-cookie';
import { BrowserView, MobileView } from 'react-device-detect'
import AssignCar from '../AssignCar'

const Block = styled.div`
    .customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    }

    .customers td, .customers th {
    border: 1px solid #ddd;
    padding: 8px;
    min-width: 120px ;
    }

    .customers tr:nth-child(even){background-color: #f2f2f2;}

    .customers tr:hover {background-color: #ddd;}

    .customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: black;
    color: white;
    }

    .customers img{
        text-align: center ;
        width : 50%;
    }

    .customers input {
        width: 100%;
    }

    .innerTable {
        border-collapse: collapse;
        width: 100%;
        margin: 10px;
    }

    
        
  

    .btn{
        width:100% ;
        height: 50px;
        
    }
`

const index = ({site}) => {
    
    const [cookie, setCookie, removeCookie] = useCookies(['auth','name']);

    //const data = match.params
   
    //const location = data.location
    //console.log(location)
    const [datas, setDatas] = useState(null)
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pagelimit, setPagelimit] = useState(10);
    const [pwd, setPwd] = useState(null)
    const handlePageChange = page => {
        
        
            setPage(page)
            setDatas(refDatas.slice((page-1)*pagelimit,(page*pagelimit)))
        

                    
    }
    const [refDatas, setRefDatas] = useState(null)
    const [tableIndex, setTableIndex] = useState(null)
    const [modiBtn, setModiBtn] = useState(false)
    const [ filterOn, setFilterOn] = useState(false)


    const [loading, setLoading] = useState(false)
    const [imgUpload, setImgUpload] = useState(false)
    const [beforeImg, setBeforeImg] = useState('')
    const [afterImg, setAfterImg] = useState('')
    const [form, setForm] = useState ({
        

        
    })

    const [startDate, setStartDate] = useState(new Date());


    const today =(value) =>{
        
        const year = value.getFullYear();
        const month = ('0' + (value.getMonth()+1)).slice(-2);
        const day = ('0' + value.getDate()).slice(-2);

        const dateString = year + '/' + month + '/' + day;

        return dateString
    }

    useEffect(() => {
              
     
        fetchGetData();
        

    },[cookie.auth])



    

    const fetchGetData = async() => {
    
        try{
                    

            const response = await axios.get(
                `${process.env.REACT_APP_HOST_RPA}/rpa`,{
                    
                    headers:{
                        'token': cookie.auth,
                        
                    }
                }
            );
            
            console.log("새로고침 ")
            
            if(response.data.length != 0){
             
                setRefDatas(response.data)
                //console.log(response.data)
                setDatas(response.data.slice((page-1)*pagelimit,(page*pagelimit)))
                setTotalCount(response.data.length)

                
            }else{
                setDatas(null)
            }
            
            
            
    
        }catch (e) {
            //console.log(e);
           
        }
        
    
      };

    const categoryFilter = useCallback((refCategory) => {
        
        if(refCategory == 'all'){
            setDatas(refDatas)
            setFilterOn(false)
        }else{
            const cateFilterData = refDatas.filter(refData => {
                //console.log(refData.status)
                if(refData.status == refCategory){
    
                    return true;
                }
               
            })

             //console.log(cateFilterData)
             setDatas(cateFilterData)
             setFilterOn(true)

        }
        
            

       
    },)

    const onChange = useCallback(e => {
    
    
        const nextForm = {
          ...form,
          [e.target.name]: e.target.value
        };

        
        setForm(nextForm);
       
        
    },[form]);

    

      const modyBtn = async(id, tableIndex) =>{
       
        //console.log("수정클릭")
        //console.log(id)
       
                

            try{

               
              

                setTableIndex(tableIndex)
                
                
                //console.log(id)
                //console.log(tableIndex)
                

                  const response = await axios.get(
                      `${process.env.REACT_APP_HOST_RPA}/rpa/search?id=${id}`,{
                        headers:{
                            'token': cookie.auth
                        }
                      }
                  );
                  
                             
      
                  console.log(response.data[0])       
                  //setModiGetDatas(response.data.qcdatas[0])     
                  setForm(response.data[0])    
                  console.log(response.data[0].rpadatacount)                
                  
                  


                  
                  
          
              }catch (e) {
                  //console.log(e);
                 
              }
        
        
        
    //    deleteData();
    }

    const submitApproveData = async(_id) => {
        
        
        if(window.confirm("등록할까요?")){
            setLoading(true)
        

            try{

                /*
                 title = "출하확정 " + sapOrderNumberTextView.getText().toString().trim();
                rpadata = idTextView.getText().toString() + " " + sapOrderNumberTextView.getText().toString().trim() + " "
                        + orderDateApproveTextView.getText().toString() + " " +rpadatacountTextView.getText().toString();

                */     
                const title = "출하확정 " + form.sapordernumber.trim();
                const rpadata = form._id + " " + form.sapordernumber.trim() + " "
                        + today(startDate) + " " + form.rpadatacount;

                      

                const enrollData = await axios.post(
                    `${process.env.REACT_APP_HOST_RPA}/rpa?title=${title}&rpadata=${rpadata}`,{
                        
                            
                    },{
                        headers:{
                            'token': cookie.auth
                        }
                               
                        }
                );


                    
                    

                    console.log(enrollData.data)
                    
                    setLoading(false)
                    alert("등록되었습니다.")
                    
                    window.location.reload();
                    //fetchGetData()
                    
                

            }catch{
                setLoading(false)
                console.log("등록 실패" )
                alert("등록이 실패하였습니다.")

            }
        
        }

        
        
      }

      const submitDocument = async(_id) => {
        
      

        if(window.confirm("등록할까요?")){
            setLoading(true)
        

            try{

                /*
                  title = "서류송부 " + sapOrderNumberTextView.getText().toString().trim();
                rpadata = idTextView.getText().toString() + " " + sapOrderNumberTextView.getText().toString().trim() + " " +
                        sapCustomerNumberTextView.getText().toString() + " " + sapOrderAmountTextView.getText().toString() + " " + rpadatacountTextView.getText().toString();

                */     
                const title = "서류송부 " + form.sapordernumber.trim();
                const rpadata = form._id + " " + form.sapordernumber.trim() + " "
                        + form.sapcustomernumber + " " +form.saporderamount + " " + form.rpadatacount;

                      

                const enrollData = await axios.post(
                    `${process.env.REACT_APP_HOST_RPA}/rpa?title=${title}&rpadata=${rpadata}`,{
                        
                            
                    },{
                        headers:{
                            'token': cookie.auth
                        }
                              
                        }
                );


                    
                    

                    console.log(enrollData.data)
                    
                    setLoading(false)
                    alert("등록되었습니다.")
                    
                    window.location.reload();
                    //fetchGetData()
                    
                

            }catch{
                setLoading(false)
                console.log("등록 실패" )
                alert("등록이 실패하였습니다.")

            }
        
        }

        
        
      }
        

    return(
            <>
            <Block>
              <table className='customers'>
                    
                    <tr>
                        <th colSpan={2}>
                        - Work List -
                        </th>
                    
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button onClick={()=> {window.location.reload()}}>새로고침</button>
                        </td>
                    </tr>

                 
                    {datas != null && <>
                        {datas.map((data,index) => (
                          
                            <>
                            <tr key={index} onClick={()=>{
                                modyBtn(data._id,index)
                                
                            }}
                            >
                            
                            <td colSpan={2}>  
                            {index} <label style={{color:'green'}}>등록일 : {data.createdAt.split("T")[0]}</label>
                                <br/>
                                 - {data.title}
                                <br/>
                                 
                                 {data.status == 1 && "[주문조회]"}
                                 {data.status == 2 && "[배차대기]"}
                                 {data.status == 3 && "[출하대기]"}
                                 {data.status == 4 && "[서류대기]"}
                                 {data.status == 5 && "[완료]"}
                                 {data.status == 6 && "[완료_공장출하]"}
                                 {data.status == 10 && <label style={{color:'red'}}>[ERROR] {data.comment != null && data.comment} </label>}
                                <br/>
                                

                            </td>
                            </tr>
                            
                            {tableIndex == index && <>
                                
                               {data.title.includes("차량등록") ? 
                               <>   
                                <tr>
                                    <td style={{width:'30%'}}>
                                        {data.title}
                                    </td>
                                    <td style={{width:'70%'}}>
                                        배차처리시 등록번호 확인해주세요 

                                    </td>
                                    
                                </tr>
                                
                                </> 
                                    :
                                    <>
                                    <table className='innerTable'>

                                    
                                    <tr>
                                            <td >
                                                주문번호
                                            </td>
                                            <td >
                                                {data.sapordernumber}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td >
                                                납품처
                                            </td>
                                            <td >
                                                {data.sapcustomernumber}

                                            </td>
                                        </tr>
                                        
                                        <tr>
                                            <td >
                                                운송회사
                                            </td>
                                            <td >
                                                {data.sapcarcompany}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td >
                                                차량번호
                                            </td>
                                            <td >
                                                {data.sapcarnumber}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td >
                                                출하요청일
                                            </td>
                                            <td >
                                                {data.saprequestdate}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td >
                                                도착요청일
                                            </td>
                                            <td >
                                                {data.saparrivedate}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td >
                                                출하확정일
                                            </td>
                                            <td >
                                                {data.sapapprovedate}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td >
                                                성적서전송 상태
                                            </td>
                                            <td >
                                                {data.sapcardocumentapproved}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td >
                                                품목수
                                            </td>
                                            <td >
                                                {data.rpadatacount}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td >
                                                총주문량
                                            </td>
                                            <td >
                                                {data.saporderamount}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td >
                                                LotNumber
                                            </td>
                                            <td >
                                                {data.saplotnumber}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td >
                                                비고
                                            </td>
                                            <td >
                                                {data.comment != null && data.comment}

                                            </td>
                                        </tr>

                                        
                                        </table>

                                        {data.status == 2 && <>
                                            <AssignCar data={data}/>
                                        
                                        </>}
                                        

                                   
                                 </>}
                                
                                
                                
                                
                            </>
                            }

                           
                        
                            </>
                          
                           
                          
                        ))}
                        </>
                    }
                     </table>

                    
                            

                 {filterOn == false && 
                    <Pagination
                    activePage={page}
                    itemsCountPerPage={pagelimit}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    prevPageText={"<"}
                    nextPageText={">"}
                    onChange={handlePageChange}
                    />

                 }

                 

                 

                 
                 
            </Block>

                             
              
            </>
    
        );
    
   
};

export default index