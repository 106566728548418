import React, { PureComponent } from 'react';
import { useState, useCallback, useEffect, useRef} from 'react';


import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';


import styled from 'styled-components'
import axios from 'axios';

import SignatureCanvas from 'react-signature-canvas'
import {Buffer} from 'buffer'
import Modal from 'react-modal'
import {v4} from 'uuid'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"


import './Paging.css'
import Pagination from 'react-js-pagination';
import DistPanelCheckWrite from './DistPanelCheckWrite';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import GroundingCheckWrite from './GroundingCheckWrite';



const Block = styled.div`
  overflow:auto;

  th, td {
    border: 1px solid lightgrey;
    padding: 3px;
    text-align: center ;
  }

`


const customStyles ={
  content: {
      top:'50%',
      left:'50%',
      right:'auto',
      bottom:'auto',
      marginRight:'-50%',
      transform: 'translate(-50%, -50%)',
      
  },
  border:{
    border: 'solid'
  }
  

 
  
}



Modal.setAppElement('#root')
const GroundingCheck = ({location, refCategory}) => {
  const [datas, setDatas] =useState(null)
  const [refDatas, setRefDatas] =useState(null)

  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies(['auth']);
  const [modalIsOpen, setIsOpen] = useState(false);
  const signCanvas = useRef() 
  const componenetRef = useRef();
  

    //console.log(match.params.location)
    //const data = match.params
   
    //const location = data.location
    
    const [category, setCategory] = useState('elec')
    const [writeOn,setWriteOn] = useState(false)
    const [readOn,setReadOn] = useState(false)
    const [idInform,setIdInform] = useState(null)
    const [login, setLogin] = useState(true)

    const [ inChargePerson, setInChargePerson ] = useState('')
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [signPath1, setSignPath1] = useState(null)
    const [signPath2, setSignPath2] = useState(null)
    const [signPath3, setSignPath3] = useState(null)
    const [detailView, setDetailView] = useState(false)


    const [filterOn, setFilterOn] = useState(false)
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pagelimit, setPagelimit] = useState(10);
    const handlePageChange = page => {
        
      setFilterOn(false)
      setPage(page)
      setDatas(refDatas.slice((page-1)*pagelimit,(page*pagelimit)))
               
  }

  const [form, setForm] = useState ({
  
  })

  const onChangeComment= useCallback((name,comment) => {
    
    //console.log(name)
    const nextForm = {
      ...form,
      [name]: comment
    };

    
    setForm(nextForm);
   
    console.log(form)
    
},[form]);


  const today =(value) => {
    const year = value.getFullYear();
    const month = ('0' +(value.getMonth()+1)).slice(-2);
    const day = ('0' + value.getDate()).slice(-2);
    const dateString = year + '-' + month +'-' + day;

    return dateString
  }

  const dateFilter = useCallback(() => {
    const filterData = refDatas.filter(refData => {
      //console.log(refData.createdAt)
      //if(today( new Date(new Date(refData.createdAt) - 32400000)) >= today(startDate) && today(new Date(new Date(refData.createdAt) - 32400000)) <= today(endDate)){
      if(today( new Date(refData.createdAt)) >= today(startDate) && today(new Date(refData.createdAt)) <= today(endDate)){
        return true
      }
    })
    console.log(startDate)
    
    console.log(filterData)
    setDatas(filterData)
    setFilterOn(true);
  })

  

    const [ tableIndex, setTableIndex ] = useState(null)

 

    const onChange = useCallback(e => {
    
    
      const nextForm = {
        ...form,
        [e.target.name]: e.target.value
      };

      
      setForm(nextForm);
     
      
  },[form]);

  useEffect(() => {
    //console.log(cookies.auth)
    fetchGetData();
    //fetchLoginCheck();
    //fetchData();

  },[])

  



  const fetchGetData = async() => {

    try{
      
      const response = await axios.get(
          `${process.env.REACT_APP_SOCKET}/allplantdata?type=${refCategory}&location=${location}`,{
              headers:{
                  token : cookie.auth
              }
          }
      );

      setDatas(response.data)
      setRefDatas(response.data)
      setTotalCount(response.data.length)
      //console.log(response.data.length)

     


    }catch (e) {
        //console.log(e);

      
    }
  }


  const fetchLoginCheck = async() => {

    try{
      
        const response = await axios.get(
            `${process.env.REACT_APP_HOST_RPA}/rpa/account/test`,{
                headers:{
                    token : cookie.auth
                }
            }
        );
        
            //console.log(response.data)
        
        if(response.data.code == 419 || response.data.code == 401)
        {
            //alert("토큰만료")
            history.push("/login")
        }

        //


    }catch (e) {
        //console.log(e);

        history.push("/login")
       
    }
  }



  

  const doneWrite = () => {
    //alert("작성되었습니다.")
    
    if(window.confirm("제출하시겠습니까?")){
        
     
             //캔버스 저장 및 평가 결과 
            //save()           

            //fetchData()
            alert("등록되었습니다.")

            setIsOpen(false)
            //fetchGetData()
            
            
            
    
    }else{
        //취소
        return false
    }
   
    
}
//서명 클리어
const clear = () => {
    //캔버스 클리어
    signCanvas.current.clear();
}
//서명 저장
const save = () => {
    
    console.log("캔버스 저장")
    
    const dataURL = signCanvas.current.toDataURL('image/png')
    const decodeURL = dataURL.replace(/^data:image\/\w+;base64,/,'');
    const buf = Buffer.from(decodeURL, 'base64')
    const blob = new Blob([buf], {type:'image/png'})
    const file = new File([blob], `${v4()}.png`,{type:'image/png'})
    console.log(file)
    
    const formData = new FormData();
    formData.append('sign', file)

    /*
    axios({
        baseURL : process.env.REACT_APP_HOST,
        url: '/pm/uploadsignimg',
        method: 'POST',
        data: formData,
        headers:{
            'Content-Type' : 'multipart/form-data',              
        },
    })
        .then(response => {
            //console.log(response.data)
            //console.log(response.data.originalname)
            
            //setReturnOriginalFilename(response.data.originalname)
            console.log(response.data.filename)
            if(inChargePerson == '담당자'){
              setSignPath1(response.data.filename)
            }else if(inChargePerson == '책임'){
              setSignPath2(response.data.filename)
            }else if(inChargePerson == '리더'){
              setSignPath3(response.data.filename)
            }
            //enrollResult(response.data.filename)

                           
            

        })
        .catch(error => {
            console.error(error)
        })

    */
   
    
}



  const openModal = async(refPerson) => {
    setIsOpen(!modalIsOpen)
    
    console.log(refPerson)
    setInChargePerson(refPerson)

    
    
}



const afterOpenModal = () => {
   
}

const closeModal = () => {
    setIsOpen(false)
    
 
    console.log("close")
}

 

    return(

      
            <Block>
               

              <div  style={{display:'inline-block'}}>
           
                  <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="yy-MM-dd"/> 
                  

              </div>
              <div  style={{display:'inline-block'}}>
           
           
              ~

              </div>
             
              <div  style={{display:'inline-block'}}>
           
           
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="yy-MM-dd"/>

                 
              

              </div>
              <div  style={{display:'inline-block', marginBottom : '30px'}}>
           
                 
                  <button onClick={() => {dateFilter()}}> 검색</button>
                  


              </div>
           
              

              <div style={{clear:'both'}}>

              </div>
              
              <div>
                
                <table>
                <p/>
                      <div style={{float:'left'}}>
                            <table className='subTable'>
                              <tr>
                                <th onClick={()=>setWriteOn(!writeOn)}>
                                  Grounding Resistance Check
                                </th>
                                
                              </tr>
                            </table>
                          
                      </div>

                   

                      <div  style={{clear:'both'}}>
                        <hr/>
                      </div>
                    {writeOn && <>
                      <GroundingCheckWrite _id={null} location={location} refCategory={refCategory} />
                     </>}
                    
                      
                    


                        

                      <table style={{width:'98%'}}>
                      

                        <tr >
                          <th>
                            _id
                          </th>
                          <th>
                            Date
                          </th>
                          <th>
                            점검내용
                          </th>

                        </tr>
                      {datas !=null && datas.map((data,index)=> <>
                        <tr key={index}>
                          <td onClick={()=>{setReadOn(!readOn), setIdInform(data._id), setTableIndex(index)}}>
                              {index}
                          </td>
                          <td>
                            {data.createdAt.split("T")[0]}
                          </td>
                            
                          <td>
                              {data.remarks}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            {readOn && <>
                              {tableIndex == index && <>
                                <GroundingCheckWrite _id={data._id} location={location} refCategory={refCategory}/>
                              </>}
                            
                          </>}

                          </td>
                        
                        </tr>
                        

                      </>)}
                      


                      </table>


                     
                      
                
                  
                 
                </table>

                {filterOn == false && 
                    <Pagination
                    activePage={page}
                    itemsCountPerPage={pagelimit}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    prevPageText={"<"}
                    nextPageText={">"}
                    onChange={handlePageChange}
                    />

                 }
              </div>

             

              <div style={{clear:'both', margin:'20px'}}>

              </div>

              

                  <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen = {afterOpenModal}
                    onRequestClose={closeModal}
                    
                    style = {customStyles}
                    contentLabel = "write"
                    >
                    
                    
                <div >
                  
                  <h3>{inChargePerson} 서명</h3>
                    <div style={customStyles.border}>
                    <SignatureCanvas penColor='black'
                        ref={signCanvas}
                        canvasProps={{width: 120, height: 120, className:'sigCanvas'}} 
                        
                        />

                        <p/>
                    </div>
                    
                    
                    <hr/>
                        <button onClick={clear}>지우기</button> <button onClick={() => doneWrite()}>제출하기 </button>
                    
        
                        
       

                    

                </div>

                                            
                                            
                </Modal>

               
                
              
         
        
             
                             
              
            </Block>
    
        );
    
   
};

export default GroundingCheck