import React, { PureComponent } from 'react';
import { useState, useCallback, useEffect } from 'react';

import { BarChart, Bar, Brush, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {  LineChart,  Line,  ReferenceLine,} from 'recharts';
import { PieChart, Pie, Sector,  } from 'recharts';
import { AreaChart, Area  } from 'recharts';

import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';


import styled from 'styled-components'
import axios from 'axios';

import {
  StatusCriticalSmall,CaretRightFill, Analytics,Optimize,Launch, Search, Announce } from 'grommet-icons';

import List from './List';
import Write from './Write';



const Block = styled.div`


  font-size: 1rem;
  

  table {
  width: 95%;
  margin: 20px;
  border-collapse: collapse;
  
  
  
  
  
  }

  th, td {
    border-bottom: 1px solid lightgrey;
    padding: 3px;
    text-align: center ;
  }

  

  Input{
    max-width: 100px;
    
    text-align: center;
  }





`


const index = ({match}) => {

  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies(['auth', 'category']);
  const refParams = match.params
  const location = match.params.site
  const version = match.params.ver

    const [category, setCategory] = useState('elec')
  
  

    return(
            <Block>
            
              <div className="layout">
                <div style={{backgroundColor:'black', margin:'-10px'}}>
                  <img src="/header_logo_black.png" style={{padding:'10px'}}/>
                  
                  <div style={{backgroundColor:'red', margin:'0px', height:'7px'}} />

                

                </div>
                <p/>
                {location == "yeosu" ? <>
                    {version == "v1" && <>
                      <Write match={match} refCategory={refParams.equipname}/>
                      <List match={match} refCategory={refParams.equipname} />
                    </>}

                </> : <>
                
                <div>
                  <h2>이 페이지는 존재하지 않습니다 :</h2>

                </div>
                </>}

             
                
              </div>

                             
              
            </Block>
    
        );
    
   
};

export default index