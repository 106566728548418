import React, { PureComponent } from 'react';
import { useState, useCallback, useEffect } from 'react';

import { BarChart, Bar, Brush, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {  LineChart,  Line,  ReferenceLine,} from 'recharts';
import { PieChart, Pie, Sector,  } from 'recharts';
import { AreaChart, Area  } from 'recharts';

import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';


import styled from 'styled-components'
import axios from 'axios';

import {
  StatusCriticalSmall,CaretRightFill, Analytics,Optimize,Launch, Search, Announce } from 'grommet-icons';


import Electric from './Electric.js'


import Rotation from './Rotation.js'
import SubStation from './Substation.js'
import Generator from './Generator.js'
import Loadcell from './Loadcell.js'
import BatteryCheck from './BatteryCheck.js';
import HeaterCheck from './HeaterCheck.js';
import DistPanelCheck from './DistPanelCheck.js';
import GroundingCheck from './GroundingCheck.js';
import Hoist from './Hoist.js';


import Write from './Write.js'
import {Mobile, PC} from '../resView.js'



const Block = styled.div`


  font-size: 1rem;
  

  table {
  width: 95%;
  margin: 20px;
  border-collapse: collapse;
  
  
  
  
  
  }

  th, td {
    border-bottom: 1px solid lightgrey;
    padding: 3px;
    text-align: center ;
  }

  

  Input{
    max-width: 100px;
    
    text-align: center;
  }





`


const index = ({match}) => {

  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies(['auth', 'category']);


  const [receivedAuthNumber, setReceivedAuthNumber] = useState(null);
  const [ sendOk, setSendOk] = useState(false)

    const [category, setCategory] = useState('elec')
    const [writeOn,setWriteOn] = useState(false)
    const [login, setLogin] = useState(false)
    const [form, setForm] = useState ({})


    const onChange = useCallback(e => {
    
    
      const nextForm = {
        ...form,
        [e.target.name]: e.target.value
      };

      
      setForm(nextForm);
     
      
  },[form]);

  

  useEffect(() => {
    //console.log(cookies.auth)
    
    //fetchData();
    fetchLoginCheck();

    
    

  },[cookie.category])


  const fetchLoginCheck = async() => {

    try{
      
        const response = await axios.get(
            `${process.env.REACT_APP_HOST_RPA}/rpa/account/test`,{
                headers:{
                    token : cookie.auth
                }
            }
        );
        
            //console.log(response.data)
        
        if(response.data.code == 419 || response.data.code == 401)
        {
            //alert("토큰만료")
            
            setLogin(false)
        }else if (response.data.code == 200){
          //history.push(`/pm/${match.params.location}`)
          setLogin(true)
        }

        //


    }catch (e) {
        //console.log(e);
        setLogin(false)
        
       
    }
  }


  const loginBtn = async() => {
    //console.log("제출 클릭 ")
    //console.log(form.id)
    //console.log(form.pwd)
    
    
      try{
        //console.log("값확인 ")
        
          const response = await axios.post(
              `${process.env.REACT_APP_HOST_RPA}/rpa/account/token?id=${form.userId}&password=${form.userPw}`
              
          );
          
          console.log(response.data.code)                
          //console.log(response.data)                
          if(response.data.code == 400)
          {
            alert("로그인정보가 존재하지 않아요")
            setLogin(false)
          }else if (response.data.code == 200){
            //1시간만 유지
            setCookie('auth',response.data.token,{maxAge:1800});
            setCookie('name',response.data.name,{maxAge:1800});
            setLogin(true)
            setSendOk(false)
  
            
            //history.push("/sales");
          }
  
  
          
          
  
      }catch (e) {
          //console.log(e);
         
      }
      
  
  
    
};

 

    return(
            <Block>
            
              <div className="layout">
                <div style={{backgroundColor:'black', margin:'-10px'}}>
                  <img src="/header_logo_black.png" style={{padding:'10px'}}/>
                  
                  <div style={{backgroundColor:'red', margin:'0px', height:'7px'}} />

                

                </div>
                <p/>
                {login ?  <>

                    <button onClick={()=>(setCategory('elec'))}>전기/계장</button> <button onClick={()=>(setCategory('rotation'))}>회전기기</button> <button onClick={()=>(setCategory('substation'))}>SubStation</button> <button onClick={()=>(setCategory('generator'))}>비상발전기</button> <button onClick={()=>(setCategory('loadcell'))}>LOAD CELL</button> <button onClick={()=>(setCategory('battery'))}>Battery</button> <button onClick={()=>(setCategory('heater'))}>Heater</button> <button onClick={()=>(setCategory('dist'))}>DIST. PANEL</button> <button onClick={()=>(setCategory('grounding'))}>Grounding</button> <button onClick={()=>(setCategory('hoist'))}>Hoist</button>
                    <p/>

                    
                    {category == "elec" &&<>
                    
                   
                      <Electric location={match.params.location} />
                    
                    
                    </>
                    }

                    {category == "rotation" &&<>
                  
                  
                      <Rotation location={match.params.location} />
                  
                    
                    </>
                    }

                    {category == "write" &&

                    <Write location={match.params.location} refCategory={'elec'} />
                    }

                    {category == "substation" &&

                    <SubStation location={match.params.location} refCategory={'substation'} />
                    }

                    {category == "generator" &&

                    <Generator location={match.params.location} refCategory={'generator'} />
                    }

                    {category == "loadcell" &&

                    <Loadcell location={match.params.location} refCategory={'loadcell'} />
                    } 

                    {category == "battery" &&

                    <BatteryCheck location={match.params.location} refCategory={'battery'} />
                    } 

                    {category == "heater" &&

                    <HeaterCheck location={match.params.location} refCategory={'heater'} />
                    } 

                    {category == "dist" &&

                    <DistPanelCheck location={match.params.location} refCategory={'dist'} />
                    } 

                    {category == "grounding" &&

                    <GroundingCheck location={match.params.location} refCategory={'grounding'} />
                    } 
                     
                    {category == "hoist" &&

                    <Hoist location={match.params.location} refCategory={'hoist'} />
                    } 


                    
                                    
                
                </> : <>
               

                <table>
                            <thead>
                                <tr>
                                    <th colSpan="2">
                                        <h3>사용자 확인</h3>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                          
                                <tr>
                                   
                                    <td>
                                        <input type="text" name='userId' onChange={onChange} placeholder="id"/>&emsp;
                                        <input type="password" name='userPw' onChange={onChange} placeholder="pw"/>
                                        &nbsp;<button className='btn' onClick={loginBtn}> 확인</button>
                                    </td>
                                </tr>
                               
                                
                            </tbody>
                        </table>
                   
                   
                  <hr/>
                  
                
                </>} 
                
                
               
                
              </div>

                             
              
            </Block>
    
        );
    
   
};

export default index