import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';

import axios from 'axios';


import { Route, Link, Switch  } from 'react-router-dom';
import Main from './components/Main';

import Agreement from './components/Agreement';
import Sales from './components/Sales';
import Login from './components/Login';

import PM from './components/PM';
import PMwrite from './components/PM/Write';
import WorkBeforeCheck from './components/WorkBeforeCheck';
import WorkSurvey from './components/WorkSurvey';
import FireCheck from './components/FireCheck'


//import { Calendar } from 'react-big-calendar';



const App = () => {
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
 
  

  return(
    
    <Switch>
      <Route path="/" component = {Main} exact={true} />     
      
      <Route path="/agreement/:location/:section" component = {Agreement} />
      
      
      <Route path="/sales" component = {Sales} />
      
      <Route path="/pm/:location" component = {PM} />
      <Route path="/pmwrite/:location" component = {PMwrite} />
      <Route path="/workbeforecheck/:ver/:location/:division/:type/:equipname" component = {WorkBeforeCheck} />
      <Route path="/worksurvey/:ver/:location" component = {WorkSurvey} />
      <Route path="/firecheck/:ver/:site/:division/:equipname/:managenumber/:location/:dlocation/:type" component = {FireCheck} />
      

      <Route path="/login" component = {Login} />
      
      
      
     
      <Route
        render ={({ location }) => (
          <div>
            <h2>이 페이지는 존재하지 않습니다.. </h2>

          </div>
        )}/>
        
    </Switch>
    
    
    

  );




};

export default App;

