import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';

  import React, { PureComponent } from 'react';
  import { useState, useCallback, useEffect, useRef} from 'react';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const ChartDraw = ({chartData, name}) => {
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text:  'Chart',
          },
        },
      };

    //const labels = ['1','2','3','4','5','6','7']; //x축 기준
    const [labels, setLabels] = useState(null)
    const [dataValue, setDataValue] = useState(null)
    
    useEffect(() => {
        console.log(chartData[1])
        setLabels(chartData[0].reverse())
        setDataValue(chartData[1].reverse())
        
        //dateList()
        //console.log(dateList())
        

    },[])
    
    

    const data = {
    labels,
    datasets: [
        {
        label: name, //그래프 분류되는 항목
        data: dataValue, //실제 그려지는 데이터(Y축 숫자)
        borderColor: 'rgb(255, 99, 132)', //그래프 선 color
        backgroundColor: 'rgba(255, 99, 132, 0.5)', //마우스 호버시 나타나는 분류네모 표시 bg
        },
        
    ],
    };


    return (
        <div className='contentWrap'>
        <div className='contentInner'>
            {data.labels != null &&
            <Line options={options} data={data } />
             }
          
        </div>
      </div>

    )

  }
  
  
  export default ChartDraw