import React from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components'
import axios from 'axios';
import Yeosu from './Yeosu';
import Daesan from './Daesan';

const Block = styled.div`
    
   

`



const index = ({match}) => {

    //console.log(match.params)
    const data = match.params
   
    const location = data.location
    //console.log(location)
        

    return(
            <>
              
                 <Block>
                 
                 {location == "yeosu" ? <>
                 <Yeosu data={match.params} /> </>
                  : 
                  <>
                 <Daesan data={match.params} />
                 </>
                 }
                 
                 
                 
                 </Block>

                             
              
            </>
    
        );
    
   
};

export default index