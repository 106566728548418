import React from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import {useCookies} from 'react-cookie';

const Block = styled.div`
    .customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    }

    .customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    min-width: 80px ;
    }

  

    .customers tr:nth-child(even){background-color: #f2f2f2;}

    .customers tr:hover {background-color: #ddd;}

    .customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: black;
    color: white;
    }

    .customers img{
        text-align: center ;
        height : 300px;
    }

    .customers input {
        width: 90%;
    }
        
  

    .btn{
        width:100% ;
        height: 50px;
        background-color: lightgray;
        color: black ;
        font-size: 18px;
        
        
    }
`

const index = ({site}) => {
    
    const [cookie, setCookie, removeCookie] = useCookies(['auth','name']);
    
    //const size = match.params.location
   
    //const location = data.location
    //console.log(site)

    const [loading, setLoading] = useState(false)
    const [imgUpload, setImgUpload] = useState(false)
    const [form, setForm] = useState ({
        orderNumber:"",
        

        
    })
    const [startDate, setStartDate] = useState(new Date());

    const onChange = useCallback(e => {
    
    
        const nextForm = {
          ...form,
          [e.target.name]: e.target.value
        };

        
        setForm(nextForm);
       
        
    },[form]);


      const submitData = async() => {
        
        if(form.orderNumber == '' ){
            alert('빈 값이 존재합니다.')
            return;
        }

        //if(form.orderNumber.length != 7 ){
        //    alert('주문번호가 올바르지 않습니다. ')
        //    return;
        //}

        if(window.confirm("등록할까요?")){
            setLoading(true)
        

            try{
                
                /*
                title = "차량등록 " + carNumberText.getText().toString();


                rpadata = carVendorText + " " +
                        carNumberText.getText().toString() + " " +
                        carNameText.getText().toString() + " " +
                        carPhoneNumberText.getText().toString();
                */
                const title = "주문조회 " + form.orderNumber.replace(/'/g,"");
                const rpadata = form.orderNumber.replace(/'/g,"");

                const enrollData = await axios.post(
                `${process.env.REACT_APP_HOST_RPA}/rpa?title=${title}&rpadata=${rpadata}`,{
                    
                },{
                    headers:{
                        'token': cookie.auth
                    }
                            
                    }
                );

        
                    
        
                    console.log(enrollData.data)
                    
                    setLoading(false)
                    alert("등록되었습니다.")
                    
                    window.location.reload();
                    
                

            }catch{
                setLoading(false)
                console.log("등록 실패" )
                alert("등록이 실패하였습니다.")

            }
        
        }

        
        
      }
        

    return(
            <>
              
                 <Block>
                 <table className='customers'>
                    
                 <tr>
                            <td colSpan='2' style={{textAlign:'center'}}>
                                주문조회 
                             
                            </td>
                            
                           
                        </tr>
                    
                    
                        <tr>
                            <td>
                                주문번호  
                            </td>
                            <td>
                                <input type='text' name='orderNumber' onChange={onChange} placeholder="주문번호를 입력해주세요 " />
                                
                            </td>
                        </tr>
                       
                            
                       
                    
                    
                 </table>

                 <button className='btn' onClick={()=>submitData()} >조회하기</button>

                 
                 
                 </Block>

                             
              
            </>
    
        );
    
   
};

export default index