import React, { PureComponent } from 'react';
import { useState, useCallback, useEffect } from 'react';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { BarChart, Bar, Brush, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {  LineChart,  Line,  ReferenceLine,} from 'recharts';
import { PieChart, Pie, Sector,  } from 'recharts';
import { AreaChart, Area  } from 'recharts';

import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';


import styled from 'styled-components'
import axios from 'axios';

import {
  StatusCriticalSmall,CaretRightFill, Analytics,Optimize,Launch, Search, Announce } from 'grommet-icons';


import Electric from './Electric.js'
import Rotation from './Rotation.js'


const Block = styled.div`
  table {
  width:100% ;
  border-collapse: collapse;
  
  
  }


  

  th, td {
    border: 1px solid lightgrey;
    padding: 3px;
    text-align: center ;
    
  }

  

  Input{
    width: 200px;
    height :25px;
    
    text-align: center;
  }

  select{
    width: 100px;
    height : 30px;
    text-align: center;
  }

  





`


const Write = ({location,id, refCategory}) => {

  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies(['auth', 'category']);

  const [startDate, setStartDate] = useState(new Date());
  const [selectDate, setSelectDate] = useState(null);

  const [category, setCategory] = useState(refCategory)
  const [writeOn,setWriteOn] = useState(false)
  const [login, setLogin] = useState(true)
  const [form, setForm] = useState ({
    rotationLampAxial : "1",
    rotationLampRadial : "1",
    rotationOilLevel : "적정",
    rotationSound : "양호",
    rotationExternal : "양호",
    remarks : '',
    checker : "유영규",

  })
  const [tagList, setTagList] = useState(null);
  const [tagInformList, setTagInformList] = useState(null);
  const [tagRefData, setTagRefData] = useState(null);
  const [loadData, setLoadData] = useState(null);




    const onChange = useCallback(e => {
      //console.log(e.target.name)
      //console.log(e.target.value)
      const nextForm = {
        ...form,
        [e.target.name]: e.target.value
      };

      
      setForm(nextForm);
     
      
  },[form]);



  

  useEffect(() => {
    //alert(id)
    if (id == null) {
      //alert('none')
    }else{
      fetchData()
    }
    

  },[id])

  //id를 가지고 세부 데이타 조회 
  const fetchData = async() => {
    try{
                    
      //console.log('car start')
      const response = await axios.get(
          `${process.env.REACT_APP_SOCKET}/plantdata/detail?id=${id}`,{
              
              headers:{
                  'token': cookie.auth
              }
          }
      );

     
      //console.log(response.data[0])
      //console.log(response.data.length)
      
      if(response.data.length != 0){
       
         setLoadData(response.data[0])
         setForm(response.data[0]) 
         setSelectDate(new Date(response.data[0].createdAt))
         
         
          
      }else{
        setLoadData(null)
        
      }
      
      
      

    }catch (e) {
        //console.log(e);
      
    }


  }

  const TagList = async(e) => {
    //console.log(e.target.value)
    
    if(e.target.value.length <= 2 ) {
      console.log(Number(e.target.value.length))
      console.log("return")
      
      
    }else{
      //검색 리스트 출력 
      //fetchData()

      try{
                    
      
        const response = await axios.get(
            `${process.env.REACT_APP_SOCKET}/plantiteminform?type=${category}&location=${location}&tag=${e.target.value}`,{
                
                headers:{
                    'token': cookie.auth
                }
            }
        );
        
        console.log(response.data)
        //console.log(response.data.articles.length)
        
        if(response.data.length != 0){

          const selected_tag = response.data.filter(data => {
            //console.log(refData.createdAt)
            //if(today( new Date(new Date(refData.createdAt) - 32400000)) >= today(startDate) && today(new Date(new Date(refData.createdAt) - 32400000)) <= today(endDate)){
            if(data.tag == e.target.value){
              return true
            }
          })
         
          console.log(selected_tag)
            
           setTagRefData(selected_tag[0])

           //검색리스트
           setTagInformList(response.data)
            
        }else{
          setTagRefData(null)
          setTagInformList(null)
        }
        
        
        
  
      }catch (e) {
          //console.log(e);
        
      }
    }
    

    
    

  };

  const removeData = async() => {
    if(window.confirm("삭제 할까요?")){
      
      

      try{
    
        
          //console.log('car start')

          
            const response = await axios.delete(
              `${process.env.REACT_APP_SOCKET}/plantdata/detail?_id=${id}`,{
                  
                    headers:{
                        'token': cookie.auth
                    }
                }
                );
                
                //console.log(response.data)
                //console.log(response.data.length)
                
                if(response.data.length != 0){
                
                  
                  alert("삭제 되었습니다.")
                    
                }else{
                  
                  alert("등록실패.")
                }
            


        

        window.location.reload();
        
        
        
        

      }catch (e) {
          //console.log(e);
        
      }

    }else{
      alert("취소되었습니다.")
    }
  }

  const submitData = async() => {
    

    //alert("clic")

    if(window.confirm("등록할까요?")){
      
      

      try{

     
                      
        if(category == "elec"){
          //console.log('car start')
          //초기등록
            if(id == null){
              const response = await axios.post(
                `${process.env.REACT_APP_SOCKET}/plantdata?type=${category}&date=${startDate}&location=${location}&tag=${tagRefData.tag}&itemName=${tagRefData.itemName}&ratingKW=${tagRefData.ratingKW}&ratingV=${tagRefData.ratingV}&ratingA=${tagRefData.ratingA}&ratingPole=${tagRefData.ratingPole}&grade=${tagRefData.grade}&rms=${tagRefData.rms}&greaseOilType=${tagRefData.greaseOilType}&operAmp=${form.operAmp}&operResist=${form.operResist}&deVibV=${form.deVibV}&deVibH=${form.deVibH}&deVibA=${form.deVibA}&ndeVibV=${form.ndeVibV}&ndeVibH=${form.ndeVibH}&ndeVibA=${form.ndeVibA}&greaseMakeDE=${form.greaseMakeDE}&greaseMakeNDE=${form.greaseMakeNDE}&greaseOilDE=${form.greaseOilDE}&greaseOilNDE=${form.greaseOilNDE}&bearingDE=${form.bearingDE}&bearingNDE=${form.bearingNDE}&remarks=${form.remarks}&checker=${form.checker}`,{
                    
                    headers:{
                        'token': cookie.auth
                    }
                }
            );


            
            console.log(response.data)
            //console.log(response.data.length)
            
            if(response.data.length != 0){
            
              
              alert("등록되었습니다.")
                
            }else{
              
              alert("등록실패.")
            }

          }else{
            //데이타 수정 
            console.log("수정")
            const response = await axios.put(
              `${process.env.REACT_APP_SOCKET}/plantdata/detail?id=${id}&createdAt=${selectDate - 32400000}&type=${category}&location=${location}&tag=${form.tag}&itemName=${form.itemName}&ratingKW=${form.ratingKW}&ratingV=${form.ratingV}&ratingA=${form.ratingA}&ratingPole=${form.ratingPole}&grade=${form.grade}&rms=${form.rms}&greaseOilType=${form.greaseOilType}&operAmp=${form.operAmp}&operResist=${form.operResist}&deVibV=${form.deVibV}&deVibH=${form.deVibH}&deVibA=${form.deVibA}&ndeVibV=${form.ndeVibV}&ndeVibH=${form.ndeVibH}&ndeVibA=${form.ndeVibA}&greaseMakeDE=${form.greaseMakeDE}&greaseMakeNDE=${form.greaseMakeNDE}&greaseOilDE=${form.greaseOilDE}&greaseOilNDE=${form.greaseOilNDE}&bearingDE=${form.bearingDE}&bearingNDE=${form.bearingNDE}&remarks=${form.remarks}&checker=${form.checker}`,{
                  
                headers:{
                  'token': cookie.auth
              }
              }
            );


            
            console.log(response.data)
            //console.log(response.data.length)
            
            if(response.data.length != 0){
            
              
              alert("수정되었습니다..")
                
            }else{
              
              alert("등록실패.")
            }

          }
            
          
        }else if( category == "rotation"){
          //console.log('car start')

          if (id == null){
            const response = await axios.post(
              `${process.env.REACT_APP_SOCKET}/plantdata?type=${category}&date=${startDate}&location=${location}&tag=${tagRefData.tag}&rotationService=${tagRefData.rotationService}&rotationType=${tagRefData.rotationType}&rotationGrade=${tagRefData.rotationGrade}&rotationSeparation=${tagRefData.rotationSeparation}&rotationVib=${form.rotationVib}&rotationOilLevel=${form.rotationOilLevel}&rotationOilMake=${form.rotationOilMake}&rotationTemp=${form.rotationTemp}&rotationSound=${form.rotationSound}&rotationExternal=${form.rotationExternal}&rotationLampAxial=${form.rotationLampAxial}&rotationLampRadial=${form.rotationLampRadial}&remarks=${form.remarks}&checker=${form.checker}`,{
                  
                    headers:{
                        'token': cookie.auth
                    }
                }
                );
                
                //console.log(response.data)
                //console.log(response.data.length)
                
                if(response.data.length != 0){
                
                  
                  alert("등록되었습니다.")
                    
                }else{
                  
                  alert("등록실패.")
                }
            

          }else{

            //data 수정 
            const response = await axios.put(
              `${process.env.REACT_APP_SOCKET}/plantdata/detail?id=${id}&type=${category}&location=${location}&tag=${form.tag}&rotationService=${form.rotationService}&rotationType=${form.rotationType}&rotationGrade=${form.rotationGrade}&rotationSeparation=${form.rotationSeparation}&rotationVib=${form.rotationVib}&rotationOilLevel=${form.rotationOilLevel}&rotationOilMake=${form.rotationOilMake}&rotationTemp=${form.rotationTemp}&rotationSound=${form.rotationSound}&rotationExternal=${form.rotationExternal}&rotationLampAxial=${form.rotationLampAxial}&rotationLampRadial=${form.rotationLampRadial}&remarks=${form.remarks}&checker=${form.checker}`,{
                  
                    headers:{
                        'token': cookie.auth
                    }
                }
                );
                
                //console.log(response.data)
                //console.log(response.data.length)
                
                if(response.data.length != 0){
                
                  
                  alert("수정되었습니다..")
                    
                }else{
                  
                  alert("등록실패.")
                }
            
            
          }
        }

        window.location.reload();
        
        
        
        

      }catch (e) {
          //console.log(e);
        
      }

    }else{
      alert("취소되었습니다.")
    }

  }

 

    return(
            <Block>
            {id == null ? <>
            <p/>
              <div className="layout">
                <div style={{backgroundColor:'black', margin:'-10px'}}>
                  
                  

                  <div style={{backgroundColor:'red', margin:'-10px', height:'7px'}} />

                  

                

                </div>
                
               
                
                <p/>
                


                {category == "elec" &&

                <>
                <table>
                  
                  <tr>
                    <td>
                      type
                    </td>
                    <td>
                      {category == "elec" ? "전기/계장" : "회전기기"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Date
                    </td>
                    <td>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="yy-MM-dd" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      tag
                    </td>
                    <td>

                      
                    <input type="search" name="transportNumberText" list="taglistdata" autoComplete='off' placeholder="Tag 검색해주세요" onChange={(e)=>TagList(e)} />
                  
                      <datalist id="taglistdata">
                          {tagInformList != null && tagInformList.map((inform, index) => (
                          <option value={inform.tag}>{inform.tag}</option>
                          ))}
                      </datalist> 
                    </td>
                  </tr>
                  {tagRefData != null &&<>
                  <tr>
                    <td colSpan={2}>
                      Item Name [ {tagRefData.itemName} ]

                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Rating [ KW : {tagRefData.ratingKW} , V : {tagRefData.ratingV}, A : {tagRefData.ratingA}]

                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      [ 등급 : {tagRefData.grade} ] [ 극수 : {tagRefData.ratingPole} ] [ 진동기준 : {tagRefData.rms} ]

                    </td>
                  </tr>
                  </>}
                  <tr>
                    <td>
                      운전전류(A)
                    </td>
                    <td>
                      <input type='text' name='operAmp' onChange={onChange} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      절연저항(MQ)
                    </td>
                    <td>
                      <input type='text' name='operResist' onChange={onChange} />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      - DE Vibration -
                    </td>
                  </tr>
                  <tr>
                    <td>
                      V (mm/s)
                    </td>
                    <td>
                      <input type='text' name='deVibV' onChange={onChange} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      H (mm/s)
                    </td>
                    <td>
                      <input type='text' name='deVibH' onChange={onChange}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      A (mm/s)
                    </td>
                    <td>
                      <input type='text' name='deVibA' onChange={onChange}/>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      - NDE Vibration -
                    </td>
                  </tr>
                  <tr>
                    <td>
                      V (mm/s)
                    </td>
                    <td>
                      <input type='text' name='ndeVibV' onChange={onChange}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      H (mm/s)
                    </td>
                    <td>
                      <input type='text' name='ndeVibH' onChange={onChange}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      A (mm/s)
                    </td>
                    <td>
                      <input type='text' name='ndeVibA' onChange={onChange}/>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      - Grease -
                    </td>
                  </tr>
                  <tr>
                    <td>
                      보충량(g) DE
                    </td>
                    <td>
                      <input type='text' name='greaseMakeDE' onChange={onChange}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      주기(M) NDE
                    </td>
                    <td>
                      <input type='text' name='greaseMakeNDE' onChange={onChange}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      주유 상태 DE
                    </td>
                    <td>
                      <input type='text' name='greaseOilDE' onChange={onChange}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      오일 상태 NDE
                    </td>
                    <td>
                      <input type='text' name='greaseOilNDE' onChange={onChange} />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      - Bearing -
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Sound(이음) DE
                    </td>
                    <td>
                      <input type='text' name='bearingDE' onChange={onChange} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Sound(이음) NDE
                    </td>
                    <td>
                      <input type='text' name='bearingNDE' onChange={onChange} />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      - 기타 -
                    </td>
                  </tr>
                  <tr>
                    <td>
                      비고 
                    </td>
                    <td>
                      <input type='text' name='remarks' onChange={onChange}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      점검자
                    </td>
                    <td>
                      <input type='text' name='checker' onChange={onChange}/>
                    </td>
                  </tr>

                </table>

                <p/>
                <button style={{width:'100%', height:'40px', backgroundColor:'lightgrey'}} onClick={()=>submitData()} >제출하기 </button>
                </>
                }

                {category == "rotation" &&
                
                <>
                <table>
                  <tr>
                    <td>
                      type
                    </td>
                    <td>
                      {category == "elec" ? "전기/계장" : "회전기기"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Date
                    </td>
                    <td>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="yy-MM-dd" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      tag
                    </td>
                    <td>
                    <input type="text" name="transportNumberText" list="taglistdata" autoComplete='off' placeholder="Tag 검색해주세요" onChange={(e)=>TagList(e)} />
                  
                      <datalist id="taglistdata">
                          {tagInformList != null && tagInformList.map((inform, index) => (
                          <option value={inform.tag}>{inform.tag}</option>
                          ))}
                      </datalist> 
                    </td>
                  </tr>

                  {tagRefData != null &&<>
                  <tr>
                    <td colSpan={2}>
                    [{tagRefData.rotationType !=null && tagRefData.rotationType.toUpperCase()}] - {tagRefData.rotationService !=null && tagRefData.rotationService}

                    </td>
                  </tr>
                  
                  <tr>
                    <td colSpan={2}>
                      [ 등급 : {tagRefData.rotationGrade !=null && tagRefData.rotationGrade.toUpperCase()} ] [ 구분 : {tagRefData.rotationSeparation != null && tagRefData.rotationSeparation.toUpperCase()} ] 

                    </td>
                  </tr>
                  </>}
                  {tagRefData !=null &&<>
                    {tagRefData.rotationSeparation != null && tagRefData.rotationSeparation.substr(0,2)== "b1" &&<>
                   <tr>
                      <td colSpan={2}>
                        - Lamp 전등상태 -
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Axial
                      </td>
                      <td>
                        <select name='rotationLampAxial' onChange={onChange}>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          
                        </select>
                        
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Radial
                      </td>
                      <td>
                        <select name='rotationLampRadial' onChange={onChange}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            
                          </select>
                        
                      </td>
                    </tr>
                    
                    
                    
                  
                  </>}
                  {tagRefData.rotationSeparation != null && tagRefData.rotationSeparation.substr(0,2)== "b2" &&<>
                   <tr>
                      <td>
                        
                      </td>
                      <td>
                        - Oil 관리 -
                      </td>
                    </tr>
                    <tr>
                      <td>
                        LEVEL점검
                      </td>
                      <td>
                        <select name='rotationOilLevel' onChange={onChange}>
                            <option value="적정">적정</option>
                            <option value="부족">부족</option>
                            <option value="보충">보충</option>
                            
                          </select>
                        
                      </td>
                    </tr>
                    <tr>
                      <td>
                        급유
                      </td>
                      <td>
                        
                        <input type='text' placeholder="ML" name='rotationOilMake' onChange={onChange}/>ML
                      </td>
                    </tr>
                    
                    
                    
                  
                  </>}

                  {tagRefData.rotationSeparation != null && tagRefData.rotationSeparation.substr(0,1)== "a" &&<>
                   <tr>
                      <td >
                        
                      </td>
                      <td >
                        - 진동측정 -
                      </td>
                    </tr>
                    <tr>
                      <td>
                        측정(mm/s)
                      </td>
                      <td>
                        <input type='text' name='rotationVib' placeholder='mm/s' onChange={onChange}/>
                      </td>
                    </tr>
                    <tr>
                      <td >
                        
                      </td>
                      <td >
                        - Oil 관리 -
                      </td>
                    </tr>
                    <tr>
                      <td>
                        LEVEL점검
                      </td>
                      <td>
                        <select name='rotationOilLevel' onChange={onChange}>
                            <option value="적정">적정</option>
                            <option value="부족">부족</option>
                            <option value="보충">보충</option>
                            
                          </select>
                        
                      </td>
                    </tr>
                    <tr>
                      <td>
                        급유
                      </td>
                      <td>
                        <input type='text' name='rotationOilMake' onChange={onChange} placeholder='ML' /> 
                      </td>
                    </tr>
                    <tr>
                      <td >
                        
                      </td>
                      <td >
                        - 온도 측정 -
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Bearing(℃)
                      </td>
                      <td>
                        <input type='text' name='rotationTemp' onChange={onChange} placeholder='℃'/> 
                      </td>
                    </tr>
                    
                   
                  
                  </>}
                  {tagRefData.rotationSeparation != null  && tagRefData.rotationSeparation== "c1" &&<>
                   <tr>
                      <td >
                        
                      </td>
                      <td >
                        진동측정
                      </td>
                    </tr>
                    <tr>
                      <td>
                        측정(mm/s)
                      </td>
                      <td>
                        <input type='text' name='rotationVib' onChange={onChange} placeholder='mm/s'/>
                      </td>
                    </tr>
                    <tr>
                      <td >
                        
                      </td>
                      <td >
                        Oil 관리
                      </td>
                    </tr>
                    <tr>
                      <td>
                        LEVEL점검
                      </td>
                      <td>
                        <select name='rotationOilLevel' onChange={onChange}>
                            <option value="적정">적정</option>
                            <option value="부족">부족</option>
                            <option value="보충">보충</option>
                            
                          </select>
                        
                      </td>
                    </tr>
                    <tr>
                      <td>
                        급유
                      </td>
                      <td>
                        <input type='text' name='rotationOilMake' onChange={onChange} placeholder='ML'/>
                      </td>
                    </tr>
                    <tr>
                      <td >
                        
                      </td>
                      <td >
                        온도 측정
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Bearing(℃)
                      </td>
                      <td>
                        <input type='text' name='rotationTemp' onChange={onChange} placeholder='℃'/>
                      </td>
                    </tr>
                    
                   
                  
                  </>}
                  
                  
                  
                  </>}
                  
                  <tr>
                      <td >
                        
                      </td>
                      <td >
                        음향
                      </td>
                    </tr>
                    <tr>
                      <td>
                        음향(이음)
                      </td>
                      <td>
                        <select name='rotationSound' onChange={onChange}>
                            <option value="양호">양호</option>
                            <option value="불량">불량</option>
                            
                          </select>
                      </td>
                    </tr>
                    <tr>
                      <td >
                        
                      </td>
                      <td >
                        육안점검
                      </td>
                    </tr>
                    <tr>
                      <td>
                        부식, 파손, LEAK등
                      </td>
                      <td>
                        <select name='rotationExternal' onChange={onChange}>
                            <option value="양호">양호</option>
                            <option value="불량">불량</option>
                            
                          </select>
                        
                      </td>
                    </tr>

                  <tr>
                      <td >
                        
                      </td>
                      <td >
                        기타
                      </td>
                    </tr>
                    
                    <tr>
                      <td>
                        Remarks
                      </td>
                      <td>
                        <input type='text' name='remarks' onChange={onChange}/>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        점검자
                      </td>
                      <td>
                      <select name='checker' onChange={onChange}>
                            <option value="유영규">유영규</option>
                            <option value="권찬웅">권찬웅</option>
                            
                          </select>
                        
                      </td>
                    </tr>

                  

                </table>

                <p/>
                <button style={{width:'100%', height:'40px', backgroundColor:'lightgrey'}} onClick={()=>submitData()} >등록하기 </button>
                </>
                }
                
               
                
              </div>


            </> : <>

            <div className="layout">
                <div style={{backgroundColor:'black', margin:'-10px'}}>
                  
                  

                  <div style={{backgroundColor:'red', margin:'-10px', height:'7px'}} />

                  

                

                </div>
                
                <div style={{margin:'20px'}}>
                <p>Data 수정 </p>
                
                </div>
                
                
                <p/>
                


                {category == "elec" &&

                <>
                <table>
                  <tr>
                    <td>
                      type
                    </td>
                    <td>
                      {category == "elec" ? "전기/계장" : "회전기기"}
                    </td>
                  </tr>
                  
                  {loadData != null &&<>
                  <tr>
                    <td>
                      tag
                    </td>
                    <td>
                      
                    <input type="text" name="transportNumberText" defaultValue={loadData.tag} readOnly />
                  
                      
                    </td>
                  </tr>
                  <tr>
                    <td>
                      날짜
                    </td>
                    <td>
                      <DatePicker selected={selectDate} onChange={(date) => setSelectDate(date)} dateFormat="yy-MM-dd" />
                    
                  
                      
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Item Name [ {loadData.itemName} ]

                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Rating [ KW : {loadData.ratingKW} , V : {loadData.ratingV}, A : {loadData.ratingA}]

                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      [ 등급 : {loadData.grade} ] [ 극수 : {loadData.ratingPole} ] [ 진동기준 : {loadData.rms} ]

                    </td>
                  </tr>
                  
                    <tr>
                      <td>
                        운전전류(A)
                      </td>
                      <td>
                        <input type='text' name='operAmp' onChange={onChange} defaultValue={loadData.operAmp} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        절연저항(MQ)
                      </td>
                      <td>
                        <input type='text' name='operResist' onChange={onChange} defaultValue={loadData.operResist} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        - DE Vibration -
                      </td>
                    </tr>
                    <tr>
                      <td>
                        V (mm/s)
                      </td>
                      <td>
                        <input type='text' name='deVibV' onChange={onChange} defaultValue={loadData.deVibV} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        H (mm/s)
                      </td>
                      <td>
                        <input type='text' name='deVibH' onChange={onChange} defaultValue={loadData.deVibH} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        A (mm/s)
                      </td>
                      <td>
                        <input type='text' name='deVibA' onChange={onChange} defaultValue={loadData.deVibA} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        - NDE Vibration -
                      </td>
                    </tr>
                    <tr>
                      <td>
                        V (mm/s)
                      </td>
                      <td>
                        <input type='text' name='ndeVibV' onChange={onChange} defaultValue={loadData.ndeVibV} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        H (mm/s)
                      </td>
                      <td>
                        <input type='text' name='ndeVibH' onChange={onChange} defaultValue={loadData.ndeVibH} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        A (mm/s)
                      </td>
                      <td>
                        <input type='text' name='ndeVibA' onChange={onChange} defaultValue={loadData.ndeVibA} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        - Grease -
                      </td>
                    </tr>
                    <tr>
                      <td>
                        보충량(g) DE
                      </td>
                      <td>
                        <input type='text' name='greaseMakeDE' onChange={onChange} defaultValue={loadData.greaseMakeDE} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        주기(M) NDE
                      </td>
                      <td>
                        <input type='text' name='greaseMakeNDE' onChange={onChange} defaultValue={loadData.greaseMakeNDE} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        주유 상태 DE
                      </td>
                      <td>
                        <input type='text' name='greaseOilDE' onChange={onChange} defaultValue={loadData.greaseOilDE} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        오일 상태 NDE
                      </td>
                      <td>
                        <input type='text' name='greaseOilNDE' onChange={onChange} defaultValue={loadData.greaseOilNDE} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        - Bearing -
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Sound(이음) DE
                      </td>
                      <td>
                        <input type='text' name='bearingDE' onChange={onChange} defaultValue={loadData.bearingDE} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Sound(이음) NDE
                      </td>
                      <td>
                        <input type='text' name='bearingNDE' onChange={onChange} defaultValue={loadData.bearingNDE} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        - 기타 -
                      </td>
                    </tr>
                    <tr>
                      <td>
                        비고 
                      </td>
                      <td>
                        <input type='text' name='remarks' onChange={onChange} defaultValue={loadData.remarks} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        점검자
                      </td>
                      <td>
                        <input type='text' name='checker' onChange={onChange} defaultValue={loadData.checker} />
                      </td>
                    </tr>
                  
                  </>}
                 

                </table>

                <p/>
                <button style={{width:'100%', height:'40px', backgroundColor:'lightgrey'}} onClick={()=>submitData()} >수정완료 </button>
                <p/>
                <button style={{width:'100%', height:'40px', backgroundColor:'pink'}} onClick={()=>removeData()} >삭제 </button>
                </>
                }

                {category == "rotation" &&
                
                <>
                
                <table>
                  <tr>
                    <td>
                      type
                    </td>
                    <td>
                      {category == "elec" ? "전기/계장" : "회전기기"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      tag
                    </td>
                    <td>
                      
                    {loadData != null && loadData.tag}
                  
                      
                    </td>
                  </tr>

                  {loadData != null &&<>
                  <tr>
                    <td colSpan={2}>
                    [{loadData.rotationType !=null && loadData.rotationType.toUpperCase()}] - {loadData.rotationService !=null && loadData.rotationService}

                    </td>
                  </tr>
                  
                  <tr>
                    <td colSpan={2}>
                      [ 등급 : {loadData.rotationGrade !=null && loadData.rotationGrade.toUpperCase()} ] [ 구분 : {loadData.rotationSeparation != null && loadData.rotationSeparation.toUpperCase()} ] 

                    </td>
                  </tr>
                  </>}
                  {loadData !=null &&<>
                    {loadData.rotationSeparation != null && loadData.rotationSeparation.substr(0,2)== "b1" &&<>
                   <tr>
                      <td colSpan={2}>
                        - Lamp 전등상태 -
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Axial
                      </td>
                      <td>
                        
                        <input type='text' name='rotationLampAxial' onChange={onChange} defaultValue={loadData.rotationLampAxial} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Radial
                      </td>
                      <td>
                        <input type='text' name='rotationLampRadial' onChange={onChange} defaultValue={loadData.rotationLampRadial} />
                      </td>
                    </tr>
                    
                    
                    
                  
                  </>}
                  {loadData.rotationSeparation != null && loadData.rotationSeparation.substr(0,2)== "b2" &&<>
                   <tr>
                      <td colSpan={2}>
                        - Oil 관리 -
                      </td>
                    </tr>
                    <tr>
                      <td>
                        LEVEL점검
                      </td>
                      <td>
                        <input type='text' name='rotationOilLevel' onChange={onChange} defaultValue={loadData.rotationOilLevel} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        급유
                      </td>
                      <td>
                        <input type='text' name='rotationOilMake' onChange={onChange} defaultValue={loadData.rotationOilMake} />
                      </td>
                    </tr>
                    
                    
                    
                  
                  </>}

                  {loadData.rotationSeparation != null && loadData.rotationSeparation.substr(0,1)== "a" &&<>
                   <tr>
                      <td colSpan={2}>
                        - 진동측정 -
                      </td>
                    </tr>
                    <tr>
                      <td>
                        측정(mm/s)
                      </td>
                      <td>
                        <input type='text' name='rotationVib' onChange={onChange} defaultValue={loadData.rotationVib} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        - Oil 관리 -
                      </td>
                    </tr>
                    <tr>
                      <td>
                        LEVEL점검
                      </td>
                      <td>
                        <input type='text' name='rotationOilLevel' onChange={onChange} defaultValue={loadData.rotationOilLevel} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        급유
                      </td>
                      <td>
                        <input type='text' name='rotationOilMake' onChange={onChange} defaultValue={loadData.rotationOilMake} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        - 온도 측정 -
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Bearing(degC)
                      </td>
                      <td>
                        <input type='text' name='rotationTemp' onChange={onChange} defaultValue={loadData.rotationTemp} />
                      </td>
                    </tr>
                    
                   
                  
                  </>}
                  {loadData.rotationSeparation != null  && loadData.rotationSeparation== "c1" &&<>
                   <tr>
                      <td colSpan={2}>
                        진동측정
                      </td>
                    </tr>
                    <tr>
                      <td>
                        측정(mm/s)
                      </td>
                      <td>
                        <input type='text' name='rotationVib' onChange={onChange} defaultValue={loadData.rotationVib} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        Oil 관리
                      </td>
                    </tr>
                    <tr>
                      <td>
                        LEVEL점검
                      </td>
                      <td>
                        <input type='text' name='rotationOilLevel' onChange={onChange} defaultValue={loadData.rotationOilLevel} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        급유
                      </td>
                      <td>
                        <input type='text' name='rotationOilMake' onChange={onChange} defaultValue={loadData.rotationOilMake} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        온도 측정
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Bearing(degC)
                      </td>
                      <td>
                        <input type='text' name='rotationTemp' onChange={onChange} defaultValue={loadData.rotationTemp} />
                      </td>
                    </tr>
                    
                   
                  
                  </>}
                  
                  
                  
                  </>}
                  
                  <tr>
                      <td colSpan={2}>
                        음향
                      </td>
                    </tr>
                    <tr>
                      <td>
                        음향(이음)
                      </td>
                      <td>
                        {loadData != null && <><input type='text' name='rotationSound' onChange={onChange} defaultValue={loadData.rotationSound}/></>}
                        
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        육안점검
                      </td>
                    </tr>
                    <tr>
                      <td>
                        부식, 파손, LEAK등
                      </td>
                      <td>
                        {loadData != null && <><input type='text' name='rotationExternal' onChange={onChange} defaultValue={loadData.rotationExternal} /></>} 
                      </td>
                    </tr>

                  <tr>
                      <td colSpan={2}>
                        기타
                      </td>
                    </tr>
                    
                    <tr>
                      <td>
                        Remarks
                      </td>
                      <td>
                        {loadData != null && <><input type='text' name='remarks' onChange={onChange} defaultValue={loadData.remarks} /></>}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        점검자
                      </td>
                      <td>
                        {loadData != null && <><input type='text' name='checker' onChange={onChange} defaultValue={loadData.checker} /></>}
                      </td>
                    </tr>

                  

                </table>

                <p/>
                <button style={{width:'100%', height:'40px', backgroundColor:'lightgrey'}} onClick={()=>submitData()} >수정완료 </button>
                <p/>
                <button style={{width:'100%', height:'40px', backgroundColor:'pink'}} onClick={()=>removeData()} >삭제 </button>
                </>
                }
                
               
                
              </div>
            
            </>}
              

            <p/>
            <br/>
              
            </Block>
    
        );
    
   
};

export default Write