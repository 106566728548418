import React, { useState, useEffect,useRef,useCallback } from 'react';
import Iframe from 'react-iframe';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';


import axios from 'axios';


const LoginBlock = styled.div`
  box-sizing: border-box;
  margin: 0px;
  
   
    .container {
      height: 90vh;
      
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .item {
      padding: 20px;
      background-color: #ffffff;
    }

    label {
      width : 200px;
    }
    input {
      
      width : 300px;
  

    }
    
    
`;


const Login = () => {
 
  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies(['auth','name','division','location','level']);
  
  const [html, setHtml] = useState();
  
  
  const [form, setForm] = useState ({
    loginId:'',
    password: '',    
  })

  
  
  const onChange = useCallback(e => {
    
    const nextForm = {
      ...form,
      [e.target.name]: e.target.value
    };
    setForm(nextForm);
   
    
  },[form]);

  useEffect(() => {
    

  },[])
  

  const onSubmit = useCallback(
    e => {
      if (form.id == "" || form.password == ""){
        
        alert("값 확인해주세요")
        e.preventDefault();
      }else{
         
        
          fetchData()

          e.preventDefault();
        
      }
     

    },
    [form],
  )

  const fetchData = async() => {
    
    try{
      //console.log("값확인 ")
      
        const response = await axios.post(
            `${process.env.REACT_APP_HOST_RPA}/rpa/account/token?id=${form.id}&password=${form.password}`
        );
        
        //console.log(response.data.code)                
        //console.log(response.data)                
        if(response.data.code == 400)
        {
          alert("로그인정보가 존재하지 않아요")
        }else{
          setCookie('auth',response.data.token,{maxAge:1800});
          setCookie('name',response.data.name,{maxAge:1800});

          
          history.push("/sales");
        }


        
        

    }catch (e) {
        //console.log(e);
       
    }
    

  };


    
    return(
        <LoginBlock>
          
          <div className="container">
            <div className="item">
              
            <img src="/header_logo.png" width={"120px"} style={{flaot:'left',padding:'0px',}} />
            <p/>
              <form method='post' onSubmit={onSubmit}> 
               
                  <input type="text" 
                        //defaultValue={result.orderTitle}
                        name="id" 
                        placeholder='Login ID...'                      
                        onChange = {onChange}
                        autoComplete='off'
                        
                        
                />
                <p></p>

             
                  <input type="password" 
                        //defaultValue={result.orderTitle}
                        name="password" 
                        placeholder='password...'                      
                        onChange = {onChange}
                        autoComplete='off'
                        
                        

                />
                <p></p>
                <button style={{width:'100%'}}>Login</button>

                <p>
                  
                </p>

              </form>
              
              
              
              
            </div>
          </div>
          
        </LoginBlock>



    );
};

export default Login