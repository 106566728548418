import React from 'react';
import { useState, useCallback,useEffect } from 'react';
import styled from 'styled-components'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import {useCookies} from 'react-cookie';

const Block = styled.div`
    .customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
     
    }

    .customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    min-width: 80px ;
    }

  

    .customers tr:nth-child(even){background-color: #f2f2f2;}

    .customers tr:hover {background-color: #ddd;}

    .customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: black;
    color: white;
    }

    .customers img{
        text-align: center ;
        height : 300px;
    }

    .customers input {
        width: 89%;
    }

    .customers select{
        width : 90%;
    }
    
        
  

    .btn{
        width:100% ;
        height: 50px;
        background-color: lightgray;
        color: black ;
        font-size: 18px;
        
        
    }

   
`

const index = ({site}) => {
    
    const [cookie, setCookie, removeCookie] = useCookies(['auth','name']);
    
    //const size = match.params.location
   
    //const location = data.location
    //console.log(site)

    const [loading, setLoading] = useState(false)
    const [imgUpload, setImgUpload] = useState(false)
    const [form, setForm] = useState ({      
        orderMnumber : '', //자재번호
        
    })
    const [startDate, setStartDate] = useState(new Date());
    const [orderDateText, setOrderDateText] = useState(new Date());

    useEffect(() => {
              
     
        fetchGetData();
        

    },[cookie.auth])

    const fetchGetData = async() => {
    
        try{
                    

            const response = await axios.get(
                `${process.env.REACT_APP_HOST_RPA}/rpa/transportvendorlist`,{
                    
                    headers:{
                        'token': cookie.auth,
                        
                    }
                }
            );
            

            //console.log("새로고침 ")


            
            if(response.data.articles.length != 0){
             
                setTransportVendorList(response.data.articles)
                //setRefDatas(response.data.articles)

                //console.log(response.data.articles)
             

                
            }else{
                setTransportVendorList(null)
            }
            
            
            
    
        }catch (e) {
            //console.log(e);
           
        }
        
    
      };

    const onChange = useCallback(e => {
    
    
        const nextForm = {
          ...form,
          [e.target.name]: e.target.value
        };

        
        setForm(nextForm);
       
        
    },[form]);

    

 
    const submitData = async() => {
    

        if(form.orderMnumber.length != 8){
            alert("자재번호가 올바르지 않습니다.")
            return;
        }
    


    if(window.confirm("등록할까요?")){
        setLoading(true)
    

            //console.log(new Date(orderDateText).toISOString().split("T")[0].replace(/-/g,"/"))
            
        try{
            const title = "재고조회 " + form.orderMnumber;
            const rpadata = form.orderMnumber;

            

            const enrollData = await axios.post(
            `${process.env.REACT_APP_HOST_RPA}/rpa?title=${title}&rpadata=${rpadata}`,{
                
            },{
                headers:{
                    'token': cookie.auth
                }
                        
                }
            );
                
                
                setLoading(false)
                alert("등록되었습니다.")
                
                window.location.reload();
                
            

        }catch{
            setLoading(false)
            console.log("등록 실패" )
            alert("등록이 실패하였습니다.")

        }
    
    }

    
    
    }
        

    return(
            <>
              
                 <Block>
                 
                 <table className='customers'>
                    
                 <tr>
                            <td colSpan='2' style={{textAlign:'center'}}>
                                자재조회 
                             
                            </td>
                            
                           
                        </tr>
                        <tr>
                            <td>
                                자재번호 
                            </td>
                            <td>
                                <input type='text' name='orderMnumber' onChange={onChange}  />
                            </td>
                        </tr>
                        
                 </table>

                 

                 <button className='btn' onClick={()=>submitData()} >자재조회</button>

                 
                 
                 </Block>

                             
              
            </>
    
        );
    
   
};

export default index