import React from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components'
import axios from 'axios';


import DaesanSurvey from './Survey/Daesan';
import YeosuSurvey from './Survey/Yeosu';


const Block = styled.div`
    
   

`



const index = ({match}) => {

    //console.log(match.params)
    const data = match.params
   
    const location = data.location
    
    console.log(match.params.ver)
    console.log(location)
        

    return(
            <>
              
                 <Block>
                 
                 {location == "yeosu" &&
                    <>
                    <YeosuSurvey data={match.params} />

                  </>}

                  {location == "daesan" &&
                    <>
                    <DaesanSurvey data={match.params} />

                  </>}
                  
                 
                 
                 </Block>

                             
              
            </>
    
        );
    
   
};

export default index